export const checkError = (errors, key) => {
  return hasError(errors, key) ? "form-group has-errors" : "form-group";
};

export const hasError = (errors, field) => {
  if (!Object.prototype.hasOwnProperty.call(errors, field)) {
    return false;
  }

  return errors[field] !== 1;
};

export const destructServerErrors = (error) => {
  if (error) {
    return error;
  }
  return [];
};

export const getError = (errors, field) => {
  if (!Object.prototype.hasOwnProperty.call(errors, field)) {
    return '';
  }
  const [error] = errors[field];
  return error;
};