import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDataByParams } from "../utils/service";
import { URL_API_BRANCH_IN_AREA } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import { ReactSVG } from "react-svg";

import "../assets/styles/block-branch/block-branch.scss";
import arrow_right from "./../assets/images/icons/arrow_right.svg";

const BlockBranch = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [dataBlockBranch, setDataBlockBranch] = useState([]);

  useEffect(() => {
    getDataByParams(URL_API_BRANCH_IN_AREA)
      .then((res) => {
        const updateData = res?.data || [];
        setDataBlockBranch(updateData);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleInformationDetails = (id) => {
    history.push(`/branchsinareas/${id}`);
  };

  return (
    <div className='blockBranch'>
      <div className='blockBranch_header'>
        <p className=' blockBranch_header_text'>{t("block-branch.header")} </p>
      </div>
      <div className='blockBranch_wrapper'>
        <Row gutter={[{ xs: 8, lg: 16, sm: 16, xl: 24, md: 16, xxl: 24 }, 24]}>
          {dataBlockBranch?.map((item) => (
            <Col
              xs={24}
              md={12}
              lg={12}
              xl={8}
              sm={12}
              key={item?.id}
              id={item.id}
            >
              <div className='blockBranch_wrapper_container'>
                <div
                  className='blockBranch_wrapper_container_imageWrapper'
                  onClick={() => handleInformationDetails(item.id)}
                >
                  <img
                    className='blockBranch_wrapper_container_image'
                    src={process.env.REACT_APP_ENDPOINT + item?.image}
                    alt={item?.name || ""}
                  />
                </div>

                <p
                  className='blockBranch_wrapper_container_title'
                  onClick={() => handleInformationDetails(item.id)}
                >
                  {item?.name}
                </p>
                <div
                  className='blockBranch_wrapper_container_button'
                  onClick={() => handleInformationDetails(item.id)}
                >
                  <p className='blockBranch_wrapper_container_button_text'>
                    {t("block-branch.button")}
                  </p>
                  <ReactSVG
                    src={arrow_right}
                    className='blockBranch_wrapper_container_button_arrow'
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default BlockBranch;
