import React, { useEffect, useState } from "react";

import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { postData } from "../utils/service";
import { URL_API_BANNERS } from "../utils/constants";

const SlideBanner = () => {
  const [dataBannerHome, setDataBannerHome] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const updateData =
          res?.data?.data
            ?.filter((item) => item.type === "HOME_PAGE")
            ?.sort((a, b) => a.position - b.position) || [];
        setDataBannerHome(updateData);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  return (
    <div className="slide_banner">
      {dataBannerHome?.length > 0 && (
        <Carousel
          autoplay
          arrows={true}
          pauseOnHover={false}
          prevArrow={<LeftOutlined />}
          nextArrow={<RightOutlined />}
        >
          {dataBannerHome?.map((item) => (
            <div id="section-banner" key={item?.id}>
              <img
                className="slide_banner-item"
                src={
                  process.env.REACT_APP_ENDPOINT +
                  (isMobile ? item?.image_mobile : item?.image)
                }
                alt={item?.title || ""}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};
export default SlideBanner;
