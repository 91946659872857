import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/category-document/category-document.scss";
import vectorNext from "../../assets/images/home-update/vector-next.svg";
import vectorPreview from "../../assets/images/home-update/vector-preview.svg";
import { URL_API_DETAIL_DOCUMENT } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { getTakenData } from "../../utils/service";
import { useTranslation } from "react-i18next";
import BannerCommon from "../../components/BannerCommon";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";

const CategoryDocumentDetail = () => {
  const { id, idCategory } = useParams();
  const [dataDetailDocument, setDataDetailDocument] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    getDataDetailDocument();
  }, [id]);

  const getDataDetailDocument = () => {
    try {
      if (id) {
        getTakenData(URL_API_DETAIL_DOCUMENT(id)).then((res) => {
          setDataDetailDocument(res?.data);
        });
      }
    } catch (error) {
      setDataDetailDocument({});
    }
  };
  const breadcrumb = [
    {
      title: t("library.home"),
      url: "/",
    },
    {
      title: t("library.library"),
      url: "/document",
    },
    {
      title: dataDetailDocument?.title,
    },
  ];
  return (
    <div className="wrapDocumentDetail">
      <div className="wrapDocumentDetail__banner">
        <BannerCommon text1={dataDetailDocument?.title} />
      </div>
      <div className="category-document container">
        <div className="banner-category-document">
          <BreadcrumbComponent dataBreadcrumb={breadcrumb} />
          <p className="title-category-document">{dataDetailDocument?.title}</p>
        </div>
        <div className="content-category-document">
          <div
            dangerouslySetInnerHTML={{ __html: dataDetailDocument?.content }}
            className="content-main ck-content"
          ></div>
        </div>
        <div className="block-next-preview-document">
          <div className="block-next-preview">
            <div className="block-next-preview__left">
              {dataDetailDocument?.prev && (
                <Link
                  to={`/document/${idCategory}/detail/${dataDetailDocument?.prev?.id}`}
                >
                  <img src={vectorPreview} alt="" />
                  {dataDetailDocument?.prev?.title}
                </Link>
              )}
            </div>
            <div className="block-next-preview__right">
              {dataDetailDocument?.next && (
                <Link
                  to={`/document/${idCategory}/detail/${dataDetailDocument?.next?.id}`}
                >
                  {dataDetailDocument?.next?.title}
                  <img src={vectorNext} alt="" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDocumentDetail;
