import React from "react";

const BannerImage = ({ image, children }) => {
  return (
    <div className="banner_image">
      <div
        className="background_image"
        style={{
          background: `url('${image}') center center no-repeat `,
        }}
      >
        {children && children}
      </div>
    </div>
  );
};

export default BannerImage;
