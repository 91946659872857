import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

import TitleLine from "../../components/TitleLine";

const { TextArea } = Input;

const DonateInfo = ({ info }) => {
  const { t } = useTranslation();

  return (
    <div className="donate_info" style={{ marginTop: "40px" }}>
      <TitleLine title={{ name: t("common.donateInfo") }} allowClear={true} />
      <TextArea value={info} autoSize rows={10} disabled />
    </div>
  );
};

export default DonateInfo;
