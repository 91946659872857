import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { postData } from "../../utils/service";
import { URL_API_BANNERS } from "../../utils/constants";

import SectionLibrary from "../Home/SectionLibrary";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import BannerCommon from "../../components/BannerCommon";
import "../../assets/styles/library-page/library-page.scss";

function LibraryPage() {
  const { t } = useTranslation("translation");
  const [imageBanner, setImageBanner] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "LIBRARY"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, [isMobile]);

  const breadcrumb = [
    {
      title: t("library.home"),
      url: "/",
    },
    {
      title: t("library.library"),
    },
  ];

  return (
    <div className="wrap">
      <div className="wrap__banner">
        <BannerCommon img={imageBanner} text1={t("library.library")} />
      </div>

      <div className="library container">
        <BreadcrumbComponent dataBreadcrumb={breadcrumb} />
        <SectionLibrary />
      </div>
    </div>
  );
}

export default LibraryPage;
