import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postData, getTakenData } from "../../utils/service";

import {
  TEXT_ERROR_API,
  URL_API_POSTS_LIST_TECHNICAL_ACTIVE,
  URL_API_RELATE_TECHNICAL,
  URL_API_DETAIL_TECHNICAL,
} from "../../utils/constants";

import IconRelate from "../../assets/images/icons/icon_relate.svg";
import IconRedirect from "../../assets/images/icons/Vector.svg";

import "../../assets/styles/technical-active/technical_v2.scss";

const PER_PAGE = 5;

const MapDetail = (props) => {
  const { t } = useTranslation();
  const id = props.match.params.id;
  const [data, setData] = useState([]);
  const history = useHistory();

  const fetchData = () => {
    getTakenData("/webbranch" + "/" + id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error();
      });
  };

  const handleRedirect = (id) => {
    getTakenData("/webbranch" + "/" + id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error();
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="technical_v2">
      <div className="container-1200">
        <div className="technical_v2-header">
          <div className="container-custom">
            <div className="technical_v2-breadcrumb">
              <ul className="p-0">
                <li>
                  <Link to="/">{t("common.home")}&nbsp;</Link>
                </li>
                <li>/&nbsp;{t("common.distribution")}&nbsp;</li>
                <li>/&nbsp;{data.name}</li>
              </ul>
            </div>
            <h3 className="technical_v2-title">{data && data.title}</h3>
          </div>
        </div>
        <div className="technical_v2-content">
          <div className="container-custom">
            <div
              dangerouslySetInnerHTML={{ __html: data && data.intro_branch }}
              className="ck-content"
            />
            <div className="technical_v2-content--relate">
              <h4 className="">Thông tin liên lạc</h4>
              <hr />
              <div className="technical_v2-content--relate-content">
                <div className="technical_v2-content--relate-content_item">
                  <div
                    className="technical_v2-content--relate-content_item-title d-flex"
                    style={{
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <p className="m-0" style={{ fontWeight: "bold" }}>
                      Đại diện:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {data.branch_leader}
                      </span>
                    </p>
                    <p className="m-0" style={{ fontWeight: "bold" }}>
                      Địa chỉ:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {data.address}
                      </span>
                    </p>
                    <p className="m-0" style={{ fontWeight: "bold" }}>
                      Điện thoại:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {data.leader_phone}
                      </span>
                    </p>
                    <p className="m-0" style={{ fontWeight: "bold" }}>
                      Email:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {data.leader_email}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="technical_v2-redirect">
          {data.prev ? (
            <div
              className="technical_v2-redirect--prev"
              onClick={() => handleRedirect(data.prev.id)}
            >
              <img
                src={IconRedirect}
                alt=""
                width="10px"
                style={{ transform: "rotate(180deg)" }}
              />
              <p className="technical_v2-redirect--title">{data.prev.name}</p>
            </div>
          ) : (
            <div></div>
          )}
          {data.next ? (
            <div className="technical_v2-redirect--next">
              <p>Đọc tiếp</p>
              <div
                className="technical_v2-redirect--next-bottom"
                onClick={() => handleRedirect(data.next.id)}
              >
                <p className="technical_v2-redirect--title">{data.next.name}</p>
                <img src={IconRedirect} alt="" width="10px" />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapDetail;
