import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

import NewsBlock from "../Home/NewsBlock";
import { postData } from "../../utils/service";
import { convertDataPosts } from "../../utils/functions";
import { URL_API_POSTS_RELATIVED } from "../../utils/constants";
import TitleLine from "../../components/TitleLine";
import DefaultImagePost from "../../assets/images/default_post_image.png";

const PER_PAGE_RELATIVE = 3;
const RelativedNews = ({ id }) => {
  const { t } = useTranslation();

  const [dataRelated, setDataRelated] = useState({
    loading: true,
    data: [],
  });
  const fetchDataRelative = () => {
    postData(URL_API_POSTS_RELATIVED(id), {
      per_page: PER_PAGE_RELATIVE,
      page: 1,
      keyword: "",
    }).then((res) => {
      setDataRelated({
        loading: false,
        data: convertDataPosts(res.data.data),
      });
    });
  };
  useEffect(() => {
    fetchDataRelative();
  }, []);

  const isHaveRelativeItem = dataRelated.data.length !== 0;
  if (!isHaveRelativeItem) return null;
  return (
    <div className="detail-related_post">
      <TitleLine title={{ name: t("common.relativePosts") }} />
      {dataRelated.loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="detail-related_post-list">
          {dataRelated.data.map((post, index) => (
            <NewsBlock
              key={index}
              news={post}
              url_endpoint="library/posts"
              defaultImage={DefaultImagePost}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RelativedNews;
