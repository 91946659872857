import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { CardKyThi } from "./KyThiCards";

import 'moment/locale/vi';

const COLOR = {
  "Kỳ thi đai đen": "#333",
  "Kỳ thi đai nâu": "#b97a57",
  "Kỳ thi lên đai xanh": "blue",
};

const KyThiCalendar = ({ data }) => {
  const history = useHistory();
  const localizer = momentLocalizer(moment);
  const [view, setView] = useState("month");

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: { backgroundColor: event.color },
    };
  };
  // const ToolBarView = () => {
  //   return <div>ddd</div>;
  // };
  return (
    <Calendar
      localizer={localizer}
      view={view}
      onView={(newView) => setView(newView)}
      events={data.map((item) => ({
        ...item,
        start: new Date(item["exam_date"]),
        end: new Date(item["exam_date"]),
        color: COLOR[item.title],
      }))}
      tooltipAccessor={""}
      onSelectEvent={(e) => {
        history.push(`/ky-thi/${e.id}`);
      }}
      eventPropGetter={eventStyleGetter}
      startAccessor="start"
      endAccessor="end"
      style={{ minHeight: 750 }}
      defaultDate={new Date()}
      // messages={{
      //   next: "sig",
      //   previous: "ant",
      //   today: "Hoy",
      //   month: "Mes",
      //   week: "Semana",
      //   day: "Día"
      // }}
      components={{
        event: (component) => {
          const { event } = component;
          const targetId = event.id;
          return (
            <OverlayTrigger
              placement="auto"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip id="tooltip-calendar">
                  <div className="text-left">
                    <CardKyThi info={event} />
                  </div>
                </Tooltip>
              }
            >
              <div id={targetId}>{event?.title}</div>
            </OverlayTrigger>
          );
        },
        // toolbar: (props) => (
        //   <ToolBarView {...props} />
        // ),
      }}
    />
  );
};

export default KyThiCalendar;
