import React from "react";
import Icon from "../assets/images/icons/icon_zoom.svg";
import { useTranslation } from "react-i18next";
import { Translator, Translate } from "react-auto-translate";

const DescriptionImage = ({ title, author, time }) => {
  const { t } = useTranslation();
  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <div className="description_image">
      <img src={Icon} alt="" />
      <div className="description_image--text">
        <h4>
          {/* <Translate> */}
          {title}
          {/* </Translate> */}
        </h4>
        <span>
          {/* <Translate> */}
          {t("common.by")} {author} | {time}
          {/* </Translate> */}
        </span>
      </div>
    </div>
    // </Translator>
  );
};

export default DescriptionImage;
