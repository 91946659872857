import React from "react";
import "./button.scss";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import arrow_right from "./../../assets/images/icons/arrow_right.svg";
export default function ButtonCommon({
  title,
  onClick = () => {},
  width,
  backgroundColor,
  fontSize,
  fontWeight,
  lineHeight,
  borderRadius,
  color,
  height,
  hoverStyle = {},
  svgIcon = arrow_right,
  offIcon = true,
  ...rest
}) {
  const [isHovered, setIsHovered] = useState(false);
  const combinedStyle = {
    width: width,
    backgroundColor: isHovered ? hoverStyle.backgroundColor : backgroundColor,
    height: height,
    borderRadius: borderRadius,
    color: isHovered ? hoverStyle.color : color,
    ...rest.style,
  };
  const textStyle = {
    fontSize: fontSize,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    color: isHovered ? hoverStyle.color : color,
  };

  return (
    <div
      className="commonButton"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={combinedStyle}
      {...rest}
    >
      <p style={textStyle} className="commonButton_text">
        {title}
      </p>

      {offIcon && <ReactSVG src={svgIcon} className="arrow-right" />}
    </div>
  );
}
