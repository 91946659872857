import { ACTION_TYPE } from "../reducer/home";
import { ACTION_TYPE as NEWS_ACTION_TYPE } from "../reducer/news";
import { MAPS_ACTION_TYPE } from "../reducer/maps";
import { EVENT_ACTION_TYPE } from "../reducer/events";
import { SEARCH_ACTION_TYPE } from "../reducer/search";
import { LIBRARY_ACTION_TYPE } from "../reducer/library";
// Home page
const setDataHomePage = (data) => {
  return {
    type: ACTION_TYPE.SET_DATA,
    payload: data,
  };
};

// NewsPage
const setDataNewsPage = (data) => {
  return {
    type: NEWS_ACTION_TYPE.SET_DATA,
    payload: data,
  };
};
// Maps
const setDatMapsPage = (data) => {
  return {
    type: MAPS_ACTION_TYPE.SET_DATA,
    payload: data,
  };
};
// Events
const setDataEventsPage = (data) => {
  return {
    type: EVENT_ACTION_TYPE.SET_DATA,
    payload: data,
  };
};
// Search
const setDataSearchPage = (data) => {
  return {
    type: SEARCH_ACTION_TYPE.SET_DATA,
    payload: data,
  };
};
// Library
const setCurrentTab = (tab) => {
  return {
    type: LIBRARY_ACTION_TYPE.SET_TAB_CURRENT,
    payload: tab,
  };
};

// Scroll page
const setScrollPage = (id) => {
  return {
    type: "SET_ID_SCROLL",
    id,
  };
};

const setHiddenHeader = (boolean) => {
  return {
    type: "SET_HEADER_HIDE",
    payload: boolean,
  };
};

export {
  // Home Page
  setDataHomePage,
  // News Page
  setDataNewsPage,
  // Maps Page
  setDatMapsPage,
  // Events Page
  setDataEventsPage,
  // Search Page
  setDataSearchPage,
  // Library
  setCurrentTab,
  // Scroll page home
  setScrollPage,
  // Hide header
  setHiddenHeader,
};
