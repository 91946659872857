import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-css";
import Button1 from "../../components/Button";
import ImageGallery from "../../components/ImageGallery";
import ModalGallery from "../../components/ModalGallery";
import TitleLine from "../../components/TitleLine";
import { CATALOG_LIBRARY, URL_API_LIBRARY } from "../../utils/constants";
import { convertDataGallery } from "../../utils/functions";
import { postData } from "../../utils/service";


const PER_PAGE_GALLERY = 6;
const breakpointColumnsObj = {
  default: 3,
  768: 2,
  576: 1,
};

const GalleryContent = ({ tabId }) => {
  const { t } = useTranslation();
  const [modalGallery, setModalGallery] = useState({
    visible: false,
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const [gallery, setGallery] = useState({
    data: [],
    isLoadmore: false,
    hasMore: true,
    paginate: null,
  });

  const DATA_POST_GALLERY = {
    per_page: PER_PAGE_GALLERY,
    catalog_id: CATALOG_LIBRARY(tabId),
    keyword: "",
  };

  // close modal gallery
  const closeModalGallery = () => {
    setModalGallery({
      visible: false,
      data: [],
    });
  };
  // Load more data
  const loadMore = () => {
    if (!gallery.isLoadmore)
      setGallery({
        ...gallery,
        isLoadmore: true,
      });
    if (gallery.paginate.next) {
      postData(URL_API_LIBRARY, {
        ...DATA_POST_GALLERY,
        page: gallery.paginate.next,
      }).then((res) => {
        const { data, paginate } = res.data;
        setGallery({
          data: data.length
            ? [...gallery.data, ...convertDataGallery(data)]
            : gallery.data,
          hasMore: paginate.next ? true : false,
          paginate,
          isLoadmore: false,
        });
      });
    }
  };
  // Fetch first time
  const fetchDataGallery = () => {
    if (!loading) setLoading(true);
    postData(URL_API_LIBRARY, DATA_POST_GALLERY).then((res) => {
      const { data, paginate } = res.data;
      setGallery({
        ...gallery,
        data: data.length ? convertDataGallery(data) : [],
        hasMore: paginate.next ? true : false,
        paginate,
      });
      if (loading) setLoading(false);
    });
  };
  const handleGallery = (gallery) => {
    setModalGallery({
      visible: true,
      data: gallery,
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchDataGallery();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const haveImages = gallery.data.length !== 0;
  return (
    <section className="library_gallery" id="images">
      <TitleLine title={{ name: t("common.photoLibrary") }} />
      {modalGallery.visible ? (
        <ModalGallery
          visible={modalGallery.visible}
          gallery={modalGallery.data}
          closeModal={closeModalGallery}
        />
      ) : null}

      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          {/* <Spinner animation="border" /> */}
        </div>
      ) : (
        <>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="gallery_wrapper"
            columnClassName="gallery_wrapper-grid_column"
          >
            {haveImages &&
              gallery.data.map((item, index) => (
                <ImageGallery
                  handleGallery={handleGallery}
                  id={item.id}
                  gallery={item.gallery_list}
                  key={index.toString()}
                  image={item.image}
                  title={item.title}
                  author={item.author}
                  time={item.time}
                  haveDescription
                />
              ))}
          </Masonry>
          {gallery.hasMore && (
            <div className="d-flex mt-5">
              <Button1
                text={t("common.loadMore")}
                onClick={loadMore}
                loading={gallery.isLoadmore}
                type="button"
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default GalleryContent;
