import React from 'react';
import { Pagination } from 'antd';

import '../assets/styles/pagiontion/pagination.scss';

const PaginationCustom = ({ current, onChange, total, pageSize }) => {
  return (
    <Pagination
      className="pagination__custom"
      current={current}
      pageSize={pageSize}
      total={total}
      showSizeChanger={false}
      onChange={onChange}
      showTitle={false}
    />
  );
};

export default PaginationCustom;
