import React from "react";
import imageBanner from "./../../assets/images/bg-banner-home.png";

import "./Banner.scss";
function BannerCommon({ img, text1 = "" }) {
  return (
    <div className="banner__wrap">
      <img
        src={img ? process.env.REACT_APP_ENDPOINT + img : imageBanner}
        alt=""
      />
      <h1>{text1}</h1>
    </div>
  );
}

export default BannerCommon;
