import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Menu } from "antd";

import {
  URL_API_BRANCH_IN_AREA,
  URL_API_CLUBS_RELATE,
  URL_API_DETAIL_BRANCH,
} from "../../utils/constants";

import { getDataByID, getTakenData } from "../../utils/service";

import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import BannerCommon from "../../components/BannerCommon";
import SliderCommon from "../../components/SliderClubCommon";
import MapCommon from "../../components/MapCommon";

import "../ClubDetail/ClubDetail.scss";

const ClubDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const history = useHistory();

  const [dataDetail, setDataDetail] = useState({});
  const [dataClubGroup, setDataClubGroup] = useState([]);
  const [imageBanner, setImageBanner] = useState("");
  const [dataRelated, setDataRelated] = useState([]);

  const getDataClub = () => {
    try {
      getTakenData(URL_API_BRANCH_IN_AREA).then((res) => {
        setDataClubGroup(res?.data);
      });
    } catch (error) {
      setDataClubGroup([]);
    }
  };

  useEffect(() => {
    getDataClub();
  }, []);

  useEffect(() => {
    if (id) {
      getDataByID(URL_API_DETAIL_BRANCH, id)
        .then((res) => {
          setDataDetail(res?.data);
          setImageBanner(res?.data.bgr_image);
        })
        .catch((error) => error);
      getTakenData(URL_API_CLUBS_RELATE(id))
        .then((res) => setDataRelated(res?.data))
        .catch((error) => error);
    }
  }, [id]);

  const groupName = useMemo(() => {
    return (
      dataClubGroup?.find((item) => dataDetail?.area_id === item?.id)?.name ||
      null
    );
  }, [dataDetail.area_id, dataClubGroup]);

  const idGroup = dataClubGroup.find((item) => item.name === groupName)?.id;

  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (history?.location?.pathname === "/") {
        history.push(`${history?.location?.pathname}?id=${dataDetail?.id}`);
      }
    });
  }, [history, dataDetail]);

  const menu = (
    <Menu>
      {dataClubGroup?.map((data) => {
        return (
          <Menu.Item key={data?.id}>
            <Link to={`/branchsinareas/${data?.id}`}>{data?.name}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const dataBreadcrumbs = [
    { title: t("club-list.home"), url: "/" },
    {
      title: t("club-list.header"),
      url: "/branchsinareas",
      menu: menu,
    },
    { title: groupName, url: `/branchsinareas/${idGroup}` },
    { title: dataDetail.name },
  ];
  const { lat, lng, address } = dataDetail;
  return (
    <>
      <BannerCommon img={imageBanner} text1={dataDetail.name} />
      <div className="container ">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />
        <div className="content">
          <div className="club-detail">
            {dataDetail?.intro_branch && (
              <div className="club-detail__introduce">
                <p className="title">{t("common.introduce")}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: dataDetail?.intro_branch,
                  }}
                />
              </div>
            )}

            {dataDetail?.main_branch_image &&
              dataDetail?.main_branch_image != "" && (
                <div className="club-detail__introduce">
                  <p className="title">{t("common.chief")}</p>
                  <div className="branch-image">
                    <img
                      src={
                        process.env.REACT_APP_ENDPOINT +
                        dataDetail?.main_branch_image
                      }
                      alt="img branch"
                    />
                  </div>
                </div>
              )}
            {dataDetail?.mains_branch && (
              <div className="club-detail__introduce">
                <p className="title">{t("common.executiveCommittee")}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataDetail?.mains_branch,
                  }}
                />
              </div>
            )}

            {dataDetail?.decision && dataDetail?.decision !== "" && (
              <div className="club-detail__introduce">
                <p className="title">{t("common.decision")}</p>
                <div className="branch-image">
                  <img
                    src={process.env.REACT_APP_ENDPOINT + dataDetail?.decision}
                    alt="img branch"
                  />
                </div>
              </div>
            )}

            {dataDetail?.contact_info && (
              <div className="club-detail__introduce--info">
                <p className="title">{t("dojo-detail.contact")}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: dataDetail?.contact_info,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <MapCommon lat={lat} lng={lng} address={address} />

        <div className="container__carouselInformation">
          <SliderCommon dataRelated={dataRelated} isButton={false} />
        </div>
      </div>
    </>
  );
};

export default ClubDetail;
