import moment from "moment";
const convertDataEvents = (data) => {
  let dataEvents;
  if (data.length !== 0) {
    dataEvents = data.map((x) => ({
      id: x.id,
      title: x.title,
      image: x.attach_files[0]
        ? x.thumbnail
          ? x.thumbnail
          : x.attach_files[0]
        : null,
      time_start: x.start_at,
      time_end: x.end_at,
      detail: x.detail,
      author: x.user ? x.user.name : "",
      address: x.address,
      type: x.type,
    }));
  }
  return dataEvents;
};

const formatDate = (date) => {
  return moment(date).format("DD.MM.YYYY");
};
const convertDataGallery = (data) => {
  let dataConvert = [];
  if (typeof data === "object" && data.length !== 0) {
    dataConvert = data.map((x) => ({
      id: x.id,
      title: x.title,
      author: x.user.name,
      time: x.created_at,
      image: x.attach_files[0].url,
      gallery_list: x.attach_files,
    }));
  }
  return dataConvert;
};
const convertDataNews = (data) => {
  let convertData;
  if (data.length !== 0) {
    convertData = data.map((x) => ({
      id: x.id,
      title: x.title,
      content: x.description,
      author: x.user ? x.user.name : "admin",
      time: x.created_at,
      image: x.attach_files[0]
        ? x.thumbnail
          ? x.thumbnail
          : x.attach_files[0]
        : null,
      time_updated: x.updated_at,
      time_start: x.start_at,
    }));
  }
  return convertData;
};
const convertDataPosts = (data) => {
  let convertData;
  if (data.length !== 0) {
    convertData = data.map((x) => ({
      id: x.id,
      title: x.title,
      content: x.description,
      author: x.user ? x.user.name : "admin",
      time: x.created_at,
      image: x.attach_files[0]
        ? x.thumbnail
          ? x.thumbnail
          : x.attach_files[0].url
        : null,
      time_updated: x.updated_at,
      time_start: x.start_at,
    }));
  }
  return convertData;
};
const convertDataBanner = (data) => {
  let convertData = [];
  if (data && data.length !== 0) {
    convertData = data.map((item) => item.image);
  }
  return convertData;
};

const stringToSlug = (str) => {
  return str
    .toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
    .replace(/[^a-z0-9\\-]/g, "-")
    .replace(/-+/g, "-");
};

const changeLanguage = (lang) => {
  let selectField = document.querySelector("#google_translate_element select");
    for (var i = 0; i < selectField.children.length; i++) {
      let option = selectField.children[i];
      if (option.value === lang) {
        selectField.selectedIndex = i;
        selectField.dispatchEvent(new Event('change'));
        break;
      }
    }
    if (selectField.value === '') {
      changeLanguage(lang);
    }

}

export {
  convertDataBanner,
  convertDataEvents,
  convertDataGallery,
  convertDataNews,
  formatDate,
  convertDataPosts,
  stringToSlug,
  changeLanguage,
};
