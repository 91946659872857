import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { Translator, Translate } from "react-auto-translate";

import { setDataHomePage } from "../../actions";
import { setScrollPage } from "../../actions/index";

import SlideBanner from "../../components/SlideBanner";
import {
  TEXT_ERROR_API,
  URL_API_HOME,
  URL_API_POSTS_LIST_TECHNICAL_ACTIVE,
} from "../../utils/constants";
import {
  convertDataBanner,
  convertDataGallery,
  convertDataNews,
} from "../../utils/functions";
import { getTakenData, postData } from "../../utils/service";

import SectionActivity from "./SectionActivity";
import SectionLibrary from "./SectionLibrary";
import BlockNews from "../../components/BlockNews";
import BlockBranch from "../../components/BlockBranch";
import BlockTechnical from "../../components/BlockTechnical";

import SectionClub from "./SectionClub";
import SectionHistory from "./SectionHistory";
import SectionInformation from "./SectionInformation";
import SectionSkill from "./SectionSkill";
import EventsContent from "./EventsContent";

import "../../assets/styles/home-update/home-update.scss";
import "../../assets/styles/home/home.scss";

const QUANTITY_POST_ACTIVITY = 5;

const Homepage = (props) => {
  const dispatch = useDispatch();
  const { dataHomePage, setDataHomePage } = props;
  const [dataHome, setDataHome] = useState({
    loading: dataHomePage.about ? false : true,
    data: dataHomePage,
  });
  // const [dataActivity, setDataActivity] = useState([]);

  // fetch Data home first time
  const fetchDataHome = () => {
    if (!dataHome.loading)
      setDataHome({
        ...dataHome,
        loading: true,
      });
    axios
      .all([
        getTakenData(URL_API_HOME),
        postData(URL_API_POSTS_LIST_TECHNICAL_ACTIVE, {
          keyword: "",
          page: 1,
          per_page: QUANTITY_POST_ACTIVITY,
          parent_id: 2,
          // id of active
        }),
      ])
      .then((res) => {
        const { banners, libraries, news, about } = res[0].data;
        const convertData = {
          libraries: convertDataGallery(libraries),
          events:
            res[1].data.data.length < QUANTITY_POST_ACTIVITY
              ? [
                  ...res[1].data.data,
                  ...res[1].data.favorites.slice(
                    0,
                    QUANTITY_POST_ACTIVITY - res[1].data.data.length
                  ),
                ]
              : res[1].data.data,
          news: convertDataNews(news),
          banners: convertDataBanner(banners),
          about: about[0],
        };
        setDataHome({
          loading: false,
          data: convertData,
        });
        setDataHomePage(convertData);
        // setDataActivity(res[1].data.data);
      })
      .catch(() => {
        alert(TEXT_ERROR_API);
      });
  };

  useEffect(() => {
    // if (!dataHomePage.about) {
    //   fetchDataHome();
    // } else {
    //   setDataHome({
    //     ...dataHome,
    //     data: dataHomePage,
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(setScrollPage(""));
    };
  }, []);

  // if (dataHome.loading) return <LoadingContainer />;

  const idScroll = useSelector((state) => state.scrollpage?.idsection);

  useEffect(() => {
    if (idScroll) {
      setTimeout(function () {
        const yOffset = -75;
        const element = document.getElementById(idScroll);
        const y =
          element?.getBoundingClientRect()?.top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 500);
    }
  }, [idScroll]);

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <div className="home_content">
      {/* <LoadingContainer hidden={!dataHome.loading} /> */}

      <SlideBanner />
      {/* <SectionHistory path={props.location.hash} /> */}
      {/* <SectionClub path={props.location.hash} /> */}
      {/* <SectionSkill path={props.location.hash} /> */}
      <div className="container">
        <BlockNews />
        <SectionActivity />
        <BlockBranch />
        <BlockTechnical />
        <SectionLibrary path={props.location.hash} />
        {/* <SectionHistory path={props.location.hash} />
        <SectionClub path={props.location.hash} />
        <SectionSkill path={props.location.hash} />
        <SectionActivity path={props.location.hash} />
        <SectionLibrary path={props.location.hash} />

        {/* <SectionInformation /> */}
        {/* About Content  */}
        {/* <AboutContent dataAbouts={dataHome.data.about} /> */}
        {/* <Container> */}
        {/* Event Content  */}
        {/* <EventsContent dataEvent={dataHome.data.events} /> */}
        {/* NewsContent */}
        {/* <NewsContent newsList={dataHome.data.news} /> */}
        {/* <Library /> */}
        {/* </Container> */}
      </div>
    </div>
    // </Translator>
  );
};

const mapStateToProps = (state) => {
  return {
    dataHomePage: state.homepage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDataHomePage: (data) => dispatch(setDataHomePage(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
