import axios from "axios";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_API,

  timeout: 10000,
});

const config = {
  headers: { "content-type": "multipart/form-data" },
};
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

// axiosInstance.defaults.headers.common[
//   "Authorization"
// ] = `Bearer ${localStorage.getItem("token")}`;

const getTakenData = async (url) => {
  const result = await axiosInstance.get(url);
  return result;
};

const getDataByID = async (url, id) => {
  const result = await axiosInstance.get(`${url}/${id}`);
  return result;
};

const getDataByParams = async (url, params) => {
  const result = await axiosInstance.get(url, { params });
  return result;
};

const postData = async (url, data) => {
  const result = await axiosInstance.post(url, data);
  return result;
};

const postDataConfig = async (url, data) => {
  const result = await axiosInstance.post(url, data, config);
  return result;
};

const putDataUrl = async (url, data) => {
  const result = await axiosInstance.put(url, data);
  return result;
};

export {
  axiosInstance,
  getDataByID,
  getTakenData,
  postData,
  putDataUrl,
  getDataByParams,
  postDataConfig,
};
