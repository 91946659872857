import React from "react";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const TitleLine = ({ title, id, className }) => {
  // const { t } = useTranslation();
  const { name, url, params } = title;
  return (
    <div
      className={`${className ? "title_line " + className : "title_line"}`}
      id={id ? id : ""}
    >
      <p>
        <Link
          className="pl-0"
          to={{
            pathname: url,
            state: {
              params,
            },
          }}
        >
          {name}
        </Link>
      </p>
    </div>
  );
};

export default TitleLine;
