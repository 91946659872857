import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import ButtonCommon from "../ButtonCommon";
import { TIME_FORMAT } from "../../settings/formatDateTime";

import defaultBanner from "./../../assets/images/default_image.svg";

import "./CardIAuthor.scss";

export default function CardAuthor({ dataAuthor }) {
  const { image, name, position, path, id, news, date } = dataAuthor;
  const history = useHistory();

  const handleAuthorInformation = () => {
    history.push(`${path}${id}`);
  };
  return (
    <div className="cardAuthor">
      <div className="cardAuthor--header">
        {image && (
          <img
            src={
              image
                ? `${process.env.REACT_APP_ENDPOINT}${image}`
                : defaultBanner
            }
            alt="author information"
          />
        )}
        <div className="cardAuthor--header cardAuthor--author">
          {name && <span className="cardAuthor--author__name"> {name} </span>}
          {position && (
            <span className="cardAuthor--author__position"> {position} </span>
          )}
        </div>
      </div>

      <div className="cardAuthor--footer">
        {date && (
          <p className="cardAuthor--footer__time">
            {moment(date, "YYYY-MM-DD").format(TIME_FORMAT.DOT_DATE_FORMAT)}
          </p>
        )}
        {news && (
          <ButtonCommon
            onClick={handleAuthorInformation}
            title={news}
            offIcon={false}
          />
        )}
      </div>
    </div>
  );
}
