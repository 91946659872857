import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

import EventItem from "../Events/EventItem";
import { postData } from "../../utils/service";
import { convertDataEvents } from "../../utils/functions";
import { URL_API_EVENT_RELATIVED } from "../../utils/constants";
import TitleLine from "../../components/TitleLine";
import { Translator, Translate } from "react-auto-translate";

const PER_PAGE_RELATIVE = 3;
const RelativedNews = ({ id }) => {
  const { t } = useTranslation();

  const [dataRelated, setDataRelated] = useState({
    loading: true,
    data: [],
  });
  const fetchDataRelative = () => {
    postData(URL_API_EVENT_RELATIVED(id), {
      per_page: PER_PAGE_RELATIVE,
      keyword: "",
      page: 1,
    }).then((res) => {
      setDataRelated({
        loading: false,
        data: res.data.data.length ? convertDataEvents(res.data.data) : [],
      });
    });
  };
  useEffect(() => {
    fetchDataRelative();
  }, []);

  const isHaveRelativeItem = dataRelated.data.length !== 0;

  if (!isHaveRelativeItem) return null;
  return (
    // <Translator>
    <div className="detail-related_post">
      <TitleLine
        // title={{ name: <Translate>t("common.relativeEvents")</Translate> }}
        title={{ name: t("common.relativeEvents") }}
      />
      {dataRelated.loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="events_wrapper">
          {dataRelated.data.map((event, index) => (
            <EventItem key={index} event={event} />
          ))}
        </div>
      )}
    </div>
    // </Translator>
  );
};

export default RelativedNews;
