export const EVENT_ACTION_TYPE = {
  SET_DATA: "SET_DATA_EVENTS",
};
const defaultState = {
  hasMore: true,
  data: [],
  paginate: null,
  loading: true,
};
const reducers = (state = defaultState, actions) => {
  switch (actions.type) {
    case EVENT_ACTION_TYPE.SET_DATA: {
      return actions.payload;
    }
    default:
      return state;
  }
};
export default reducers;
