import React from "react";
import logo from "../../assets/images/icons/Logo.png";
import iconFrame from "../../assets/images/frame.png";

const RegisterSuccess = ({}) => {
  return (
    <div className="block-success" id="block-success">
      <img className="block-success__icon" src={logo} alt="" />
      <div className="block-language">
        <div className="block-language__item">
          <input
            id="en-language-success"
            name="language-success"
            type="radio"
          />
          <label htmlFor="en-language-success">EN</label>
        </div>
        <div className="block-language__item">
          <input
            id="vi-language-success"
            name="language-success"
            type="radio"
            checked
          />
          <label htmlFor="vi-language-success">VI</label>
        </div>
      </div>
      <div className="row content-success">
        <div className="col-12 col-md-6 content-success__left">
          <h3 className="content-success__title">Xin cảm ơn</h3>
          <p className="content-success__description">
            Chúng tôi đã nhận được thông tin và sẽ liên hệ lại với bạn sớm nhất
            có thể.
          </p>
        </div>
        <div className="col-12 col-md-6 content-success__right">
          <img className="content-success__img" src={iconFrame} alt="" />
          <p className="content-success__description content-success__description-mobile">
            Chúng tôi đã nhận được thông tin và sẽ liên hệ lại với bạn sớm nhất
            có thể.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
