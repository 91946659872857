import React from "react";
import Button1 from "../../components/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectToHome = () => {
    history.push("/");
  };
  return (
    <div className="page_404 container">
      <h2>404 {t("common.title404")}</h2>
      <h3>{t("common.desc404")}</h3>
      <Button1 text="Home" onClick={redirectToHome} type="button"></Button1>
    </div>
  );
};

export default Page404;
