import React, { useState, useEffect } from "react";
import { URL_API_ACTIVITY_HOME } from "../../utils/constants";
import { getTakenData } from "../../utils/service";
import { stringToSlug } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Translate } from "react-auto-translate";
import Slider from "react-slick";
import { Col, Row } from "antd";

import defaultBanner from "./../../assets/images/default_image.svg";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
};
const SectionActivity = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const getDataActivity = () => {
    try {
      getTakenData(URL_API_ACTIVITY_HOME).then((res) => {
        const resData = res?.data?.sort((a, b) => {
          if (a?.position !== b?.position) {
            return a?.position - b?.position;
          }
          return new Date(b?.updated_at) - new Date(a?.updated_at);
        });

        const dataTmp = resData?.map((item) => {
          return {
            ...item,
            slugUrl: stringToSlug(item.title),
          };
        });
        setData(dataTmp);
      });
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    getDataActivity();
  }, []);

  const convertDataHtml = (data) => {
    const re = /<.*?[>]*>/gi;
    const result = data.match(re);
    const converted_result = [];
    if (result?.length > 0) {
      let _tmps = [];
      let _tmps_only_text = [];
      for (let i = 0; i < result.length - 1; i++) {
        let start = data.indexOf(result[i]);
        let _start = start + result[i].length;
        let end = data.indexOf(result[i + 1]);
        let _end = data.indexOf(result[i + 1]);
        if (start === end) {
          _end = data.indexOf(result[i + 1], 1);
          end = data.indexOf(result[i + 1], 1) + result[i + 1].length;
        } else {
          end = end + result[i + 1].length;
        }
        let tmp = data.substring(start, end);
        _tmps.push(tmp);
        let only_text = data.substring(_start, _end);
        if (only_text !== "" && only_text !== "&nbsp;") {
          _tmps_only_text.push(only_text);
        } else {
          _tmps_only_text.push(only_text.replace("&nbsp;", " "));
        }
        data = data.substring(end - result[i + 1].length);
      }

      for (let i = 0; i < result.length - 1; i++) {
        if (i > 0) {
          converted_result.push({
            beforeTag: "",
            content: _tmps_only_text[i],
            afterTag: result[i + 1],
          });
        } else {
          converted_result.push({
            beforeTag: result[i],
            content: _tmps_only_text[i],
            afterTag: result[i + 1],
          });
        }
      }
    }
    return converted_result;
  };
  const convertDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  return (
    <section className="section-activity-home" id="section-activity">
      <div className="block-title-main__title">
        <p className="block-title-main__title-main">
          <Translate>{t("home-page.title-activity")}</Translate>
        </p>
      </div>
      {/* <Slider {...settings}> */}
      <div className="block-item-activity">
        <Row gutter={[{ xs: 8, lg: 16, sm: 16, xl: 24, md: 16, xxl: 24 }, 24]}>
          {data?.slice(0, 8)?.map((item, index) => {
            const date = convertDate(item?.created_at);
            return (
              <Col xs={24} md={12} lg={8} xl={6} key={item?.id}>
                <div className="block-item-activity__content">
                  <div className="block-item-activity__content--image">
                    <img
                      src={
                        item?.thumbnail || item?.image
                          ? process.env.REACT_APP_ENDPOINT + item?.image
                          : defaultBanner
                      }
                      alt={item?.title || ""}
                    />
                  </div>
                  <div className="block-item-activity__content--title">
                    <p>{item?.menu.name}</p>
                  </div>
                  <Link to={`/activity-new/${item?.id}`}>
                    <Translate>{item?.title}</Translate>
                  </Link>
                  <div className="block-item-activity__content--date">
                    <hr />
                    <p>{date}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      {/* </Slider> */}
    </section>
  );
};

export default SectionActivity;
