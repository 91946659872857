import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { URL_CLUB } from "../../utils/constants";

import CardClub from "../CardClub";

import prevIcon from "../../assets/images/icons/prev-icon.svg";
import nextIcon from "../../assets/images/icons/next-icon.svg";

import "./slider-club-common.scss";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={prevIcon} alt="arrow-prev-logo" width={32} height={32} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={nextIcon} alt="arrow-next-logo" width={32} height={32} />
    </div>
  );
}

function SliderCommon({ dataRelated }) {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const settings = {
    dots: false,
    swipeToSlide: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SampleNextArrow />,
    nextArrow: <SamplePrevArrow />,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576.01,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
    ],
  };
  const handleBranchDetail = (id) => {
    history.push(`${URL_CLUB}${id}`);
  };
  return (
    <>
      {dataRelated?.length !== 0 ? (
        <div className="wrapSliderClub">
          <div className="wrapSliderClub__header">
            <p> {t("club-list.relate")}</p>
            <hr />
          </div>
          {dataRelated?.length > 1 ? (
            <div className="wrapSliderClub__container">
              <Slider {...settings}>
                {dataRelated?.map((item) => (
                  <div
                    key={item?.id}
                    className="wrapSliderClub__card"
                    onClick={() => handleBranchDetail(item?.id)}
                  >
                    <CardClub
                      data={{
                        image: item?.image || item?.bgr_image,
                        name: item?.name,
                      }}
                      isButton={false}
                      handleClick={handleBranchDetail}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div
              className="wrapSliderClub__card"
              onClick={() => handleBranchDetail(dataRelated?.[0].id)}
            >
              <CardClub
                data={{
                  image: dataRelated?.[0].image || dataRelated?.[0].bgr_image,
                  name: dataRelated?.[0].name,
                }}
                isButton={false}
                handleClick={handleBranchDetail}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default SliderCommon;
