import React, { useEffect, Fragment } from "react";
import { withRouter, useLocation } from "react-router-dom";

function ScrollToTop({ history, children }) {
  const { pathname, search } = useLocation();
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
