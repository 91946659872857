import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";

import LoadingContainer from "../../components/LoadingContainer";
import "../../assets/styles/kyThi/kyThi.scss";
import VectorType from "../../assets/images/icons/VectorType.svg";
import vectorUserDetail from "../../assets/images/icons/vectorUserDetail.svg";
import WallClockDetail from "../../assets/images/icons/wallclockDetail.svg";
import VectorMapDetail from "../../assets/images/icons/VectorMapDetail.svg";
import EditDetail from "../../assets/images/icons/editDetail.svg";
import { Button } from "antd";
import { getDataByID } from "../../utils/service";
import { TEXT_ERROR_API, URL_API_EXAM_DETAIL } from "../../utils/constants";

const OBJECT_DATA = [
  { name: "certificate", icon: VectorType },
  { name: "doiTuong", icon: vectorUserDetail },
  { name: "address", icon: VectorMapDetail },
  { name: "regis_start_date", isDate: true, icon: WallClockDetail },
  { name: "regis_expiry_date", isDate: true, icon: WallClockDetail },
  { name: "exam_date", isDate: true, icon: WallClockDetail },
  { name: "content_notify", isContent: true, icon: EditDetail },
];

const SectionWrapper = ({ icon, title, isInline, children }) => {
  return (
    <section className="mb-2">
      <div className="row">
        <div className="col-12 col-md-7 col-lg-5 kyThi-detail-title">
          <span className="">
            <img src={icon} alt="icon" />
          </span>
          <span className="">{title}:</span>
        </div>
        {isInline && <div className="col-12 col-md-5 col-lg-7">{children}</div>}
      </div>
      {!isInline && <div className="kyThi-detail-content">{children}</div>}
    </section>
  );
};

const demo = {
  address: "Đà Nẵng",
  attach_files: [],
  beltInfo: { id: 3, name: "Đai Xanh" },
  branchInfo: null,
  certificate: "Đai Xanh",
  clubInfo: null,
  content_notify: "",
  exam_date: "2021-01-02T17:00:00.000Z",
  exam_overview: "",
  id: 118,
  is_sendnotify: false,
  num_examiner: 10,
  num_expected_candidates: 200,
  num_member: 0,
  num_member_regis: 0,
  par_conditions: 2,
  regis_expiry_date: "2020-12-30T00:00:00.000Z",
  regis_start_date: "2020-12-29T00:00:00.000Z",
  status: 4,
  title: "Kỳ thi lên đai xanh xanh",
  title_files: "",
};

const KyThiDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [dataKyThi, setDataKyThi] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchDataKyThi = () => {
    getDataByID(URL_API_EXAM_DETAIL, id)
      .then((res) => {
        setDataKyThi(res.data);
        setLoading(false);
      })
      .catch(() => alert(TEXT_ERROR_API));
  };

  useEffect(() => {
    fetchDataKyThi();
  }, []);

  if (loading || !dataKyThi.id) {
    return <LoadingContainer />;
  }

  return (
    <>
      <LoadingContainer hidden />
      <div className="kyThi">
        <div className="kyThi-header">
          <div className="container">
            <div className="kyThi-breadcrumb">
              <ul>
                <li>
                  <Link to="/">{t("common.home")} - &nbsp;</Link>
                </li>
                <li>
                  <Link to="/ky-thi">{t("common.exam")}</Link>
                </li>
              </ul>
            </div>
            <h3 className="kyThi-title text-center">
              {t("common.titleDetailExam")}
            </h3>
          </div>
        </div>

        <div className="container pt-5 mb-5">
          {OBJECT_DATA.map((item, index) => {
            return (
              <SectionWrapper
                key={index}
                title={t(`exam.${item.name}`)}
                icon={item.icon}
                isInline={item.name !== "content_notify"}
              >
                {item.isContent ? (
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{ __html: dataKyThi[item.name] }}
                  />
                ) : (
                  <p className="mb-0">
                    {/* {moment("2020-12-30T00:00:00.000Z").format("YYYY")} */}
                    {item.isDate
                      ? moment(dataKyThi[item.name]).format("DD-MM-YYYY HH:mm")
                      : dataKyThi[item.name]}
                  </p>
                )}
              </SectionWrapper>
            );
          })}

          <div className="pt-3">
            <Button htmlType="button" className="kyThi-btn active">
              Danh sach vo sinh
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KyThiDetail;
