import React from "react";
import { checkError } from "../../helpers/error";

const InputText = React.forwardRef((props, ref) => {
  const { errors, ...input } = props;
  return (
    <div className={checkError(errors, input.name)}>
      {input.label && <label className="input-label">{input.label}</label>}
      <input {...input} ref={ref} />
    </div>
  );
});

export default InputText;
