import React from "react";

import { Input } from "antd";

import "./../TextArea/text-area.scss";

const TextAreaCommon = ({ label, isRequired = false, ...rest }) => {
  return (
    <div className="wrap-input">
      {label && (
        <span className="label">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </span>
      )}
      <Input.TextArea autoSize={{ minRows: 4, maxRows: 6 }} {...rest} />
    </div>
  );
};

export default TextAreaCommon;
