import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postData } from "../utils/service";
import { URL_API_NEWS } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { Carousel, Typography } from "antd";
import { ReactSVG } from "react-svg";

import arrow_right from "./../assets/images/icons/arrow_right.svg";
import backgroundImg from "../assets/images/home-update/backgroupd.png";

import "../assets/styles/block-news/block-news.scss";

const BlockNews = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();

  const [dataBlockNews, setDataBlockNews] = useState([]);

  useEffect(() => {
    postData(URL_API_NEWS, { is_show_new: true })
      .then((res) => {
        const updateData =
          res?.data?.data?.sort((a, b) => a.position - b.position) || [];
        const timeArray = updateData.map((data) => new Date(data.updated_at));
        const latestDate = timeArray.reduce((latest, current) => {
          return current > latest ? current : latest;
        });
        const dataLastUpdated = updateData.filter(
          (data) => data.updated_at == latestDate.toISOString()
        );
        setDataBlockNews(dataLastUpdated);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleInformationDetails = (id) => {
    history.push(`/web-news/${id}`);
  };

  const maxSlide = 6;
  const slideShow =
    dataBlockNews.length > maxSlide ? maxSlide : dataBlockNews.length;

  return (
    <div className="blockNews">
      <img src={backgroundImg} alt="background" className="background" />
      <div className="blockNews_header">
        <p className="blockNews_header_text">{t("block-news.header")} </p>
      </div>
      {/* <Carousel autoplay> */}
      {dataBlockNews?.slice(0, slideShow)?.map((item) => (
        <div className="blockNews_container" key={item?.id}>
          <div className="blockNews_container_image">
            <img
              src={process.env.REACT_APP_ENDPOINT + item?.thumbnail}
              alt={item?.title || ""}
            />
          </div>
          <div className="blockNews_container_content">
            <div className="blockNews_container_content_title">
              <p>{item?.title}</p>
            </div>
            <div className="blockNews_container_content_text">
              <Typography.Paragraph
                ellipsis={{
                  rows: 7,
                  expandable: false,
                  symbol: "...",
                }}
              >
                {item?.description}
              </Typography.Paragraph>
            </div>
            <div className="blockNews_container_content_day">
              <hr className="blockNews_container_content_day_hr" />
              {item?.created_at}
            </div>
            <div
              className="blockNews_container_content_button"
              onClick={() => handleInformationDetails(item?.id)}
            >
              <p className="blockNews_container_content_button_text">
                {t("block-news.button")}
              </p>
              <ReactSVG src={arrow_right} className="arrow-right" />
            </div>
          </div>
        </div>
      ))}
      {/* </Carousel> */}
      {dataBlockNews.length > maxSlide && (
        <div className="blockNews_moreSlides">...</div>
      )}
    </div>
  );
};
export default BlockNews;
