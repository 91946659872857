import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

import TitleLine from "../../components/TitleLine";
import BookItem from "./BookBlock";
import Button from "../../components/Button";

import { URL_API_LIBRARY, CATALOG_LIBRARY } from "../../utils/constants";
import { postData } from "../../utils/service";

const PER_PAGE_BOOK = 10;

const BookContent = ({ tabId }) => {
  const { t } = useTranslation();
  const [books, setBooks] = useState({
    hasMore: true,
    data: [],
    isLoadmore: false,
    paginate: null,
  });
  // Data Post
  const DATA_POST_BOOK = {
    per_page: PER_PAGE_BOOK,
    catalog_id: CATALOG_LIBRARY(tabId),
    keyword: "",
  };
  const [loading, setLoading] = useState(true);
  // Loadmore Data
  const loadMore = () => {
    const { paginate } = books;
    if (paginate.next) {
      if (!books.isLoadmore)
        setBooks({
          ...books,
          isLoadmore: true,
        });
      postData(URL_API_LIBRARY, {
        ...DATA_POST_BOOK,
        page: paginate.next,
      }).then((res) => {
        const { data, paginate } = res.data;
        setBooks({
          isLoadmore: false,
          hasMore: paginate.next ? true : false,
          data: data.length
            ? [...books.data, ...convertDataBook(data)]
            : books.data,
        });
      });
    }
  };
  // Convert Data
  const convertDataBook = (data) => {
    let dataConvert = [];
    if (data.length !== 0) {
      dataConvert = data.map((book) => ({
        id: book.id,
        image: process.env.REACT_APP_ENDPOINT + book.bgr_image,
        name: book.title,
        author: "Admin",
        url: book.attach_files[0].url,
      }));
    }
    return dataConvert;
  };
  // Fetch first
  const fetchDataBook = () => {
    if (!loading) setLoading(true);
    postData(URL_API_LIBRARY, {
      ...DATA_POST_BOOK,
      page: 1,
    }).then((res) => {
      const { data, paginate } = res.data;
      if (loading) setLoading(false);
      setBooks({
        isLoadmore: false,
        hasMore: paginate.next ? true : false,
        data: data.length ? convertDataBook(data) : [],
        paginate,
      });
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchDataBook();
    return () => {
      mounted = false;
    };
  }, []);

  const isHaveBooks = books.data.length !== 0;
  const isHasmorebook = books.hasMore;
  return (
    <section id="ebook">
      <TitleLine title={{ name: "EBOOK" }} />
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="ebook_wrapper">
            {isHaveBooks &&
              books.data.map((book, index) => (
                <BookItem key={index.toString()} book={book} />
              ))}
          </div>
          {isHasmorebook && (
            <div className="d-flex mt-5">
              <Button
                text={t("common.loadMore")}
                onClick={loadMore}
                loading={books.isLoadmore}
                type="button"
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default BookContent;
