import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import ImageGallery from "react-image-gallery";
import { Translator, Translate } from "react-auto-translate";

const ModalGallery = ({ visible, gallery, closeModal }) => {
  const [data, setData] = useState([]);
  const convertData = (data) => {
    let convertData = [];
    convertData = data.map((x) => {
      const linkImage = x.url ? x.url : x;
      return {
        original: process.env.REACT_APP_ENDPOINT + linkImage,
        // description: <Translate>x.title</Translate>,
        description: x.title,
        thumbnail: process.env.REACT_APP_ENDPOINT + linkImage,
      };
    });
    return convertData;
  };

  useEffect(() => {
    if (gallery && gallery.length !== 0) {
      setData(convertData(gallery));
    }
  }, []);
  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <Modal
      visible={visible}
      getContainer={false}
      width={"calc(100vw - 100px"}
      height={"calc(100vh - 100px)"}
      onCancel={closeModal}
      className="modal_gallery"
      centered
      zIndex={100}
      footer={null}
    >
      {data.length !== 0 && (
        <ImageGallery
          showPlayButton={data.length > 1}
          thumbnailClass="xxxxxx"
          items={data}
          lazyLoad
        />
      )}
    </Modal>
    // </Translator>
  );
};

export default ModalGallery;
