import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import imgClub from "./../../assets/images/home-update/item-activity.png";

import arrow_right from "./../../assets/images/icons/arrow_right.svg";

import "./../CardClub/CardClub.scss";
import { ReactSVG } from "react-svg";
function CardClub({ data, isButton = true, handleClick, technical = false }) {
  const { t } = useTranslation();
  return (
    <div className="card_club">
      <div className="card_club__image" onClick={() => handleClick(data.id)}>
        <img
          src={
            data?.image || data?.bgr_image
              ? process.env.REACT_APP_ENDPOINT + (data?.image || data.bgr_image)
              : imgClub
          }
          alt={data.name}
        />
      </div>
      <div
        className={`card_club__title ${
          technical ? `card_club__title--technical-detail` : ``
        }`}
        onClick={() => handleClick(data.id)}
      >
        {data.name}
      </div>
      <div
        className="card_club__button"
        style={{ display: isButton ? "flex" : "none" }}
        onClick={() => handleClick(data.id)}
      >
        {t("club-list.button")}
        <ReactSVG src={arrow_right} className="arrow-right" />
      </div>
    </div>
  );
}

export default CardClub;
