import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { URL_API_SEARCH, TEXT_ERROR_API } from "../../utils/constants";
import "../../assets/styles/search/search.scss";
import IconSearch from "../../assets/images/icons/search.svg";
import ImageDemo from "../../assets/images/icons/logo.svg";
import CollapseIcon from "../../assets/images/icons/collapseIcon.svg";
import { postData } from "../../utils/service";
import ModalHonor from "./ModalHornor";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Pagination } from "antd";
import { Translator, Translate } from "react-auto-translate";

const List = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataMember, setDataMember] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const fetchData = () => {
    postData("webtypicalusers", { keyword: "", per_page: 10 })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  const handleChangeValueSearch = (event) => {
    setValue(event.target.value);
  };

  const handleSearch = () => {
    postData(URL_API_SEARCH, {
      keyword: value,
      per_page: 10,
    })
      .then((res) => {
        setData(res.data.data);
        setTitle(true);
        if (value === "") {
          return setTitle(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleOpenDetail = (record) => {
    setSelectedUser(record);
  };

  const handlePrintDetail = (record) => {
    setDataMember(record);
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <div className="search_v2">
      {selectedUser && (
        <ModalHonor closeModal={closeModal} record={selectedUser} />
      )}

      <div className="container-1200">
        <div className="search_v2-header">
          <div className="container-custom">
            <div className="search_v2-breadcrumb">
              <ul className="p-0">
                <li>
                  <Link to="/">
                    <Translate>{t("common.home")}</Translate>&nbsp;
                  </Link>
                </li>
                <li>
                  /&nbsp;<Translate>{t("header.search")}</Translate>
                </li>
              </ul>
            </div>
            <h3 className="technical_v2-title">
              <Translate>{t("search.title")}</Translate>
            </h3>
            <div className="search_v2-form">
              <input
                type="text"
                placeholder={t("search.placeholder")}
                value={value}
                onChange={handleChangeValueSearch}
              />
              <button onClick={handleSearch}>
                <p>
                  <Translate>{t("search.search")}</Translate>
                </p>
                <img src={IconSearch} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="search_v2-content">
          <div className="container-fluid">
            {title ? (
              <h4 className="search_v2-content--title">
                <Translate>{t("search.result")}</Translate>
              </h4>
            ) : (
              <h4 className="search_v2-content--title">
                <Translate>{t("search.list")}</Translate>
              </h4>
            )}
            <div className="search_v2-content--list">
              <table class="list-table">
                <tr>
                  <th style={{ width: "90px", paddingLeft: "20px" }}>
                    <Translate>{t("search.id")}</Translate>
                  </th>
                  <th style={{ paddingLeft: "20px" }}>
                    <Translate>{t("search.fullName")}</Translate>
                  </th>
                  <th style={{ minWidth: "120px", paddingLeft: "20px" }}>
                    <Translate>{t("search.dob")}</Translate>
                  </th>
                  <th style={{ paddingLeft: "20px" }}>
                    <Translate>{t("search.add")}</Translate>
                  </th>
                  <th style={{ paddingLeft: "20px" }}>
                    {" "}
                    <Translate>{t("search.clb")}</Translate>
                  </th>
                  <th style={{ paddingRight: "30px", paddingLeft: "20px" }}>
                    <Translate>{t("search.dojo")}</Translate>
                  </th>
                  <th></th>
                </tr>
                {data &&
                  data.map((item, index) => {
                    // setDataMember(item);
                    return (
                      <tr
                        key={item.id}
                        index={index}
                        className={`${index % 2 === 0 ? "even" : "odd"}`}
                      >
                        <td>{item.id}</td>
                        <td
                          style={{
                            minWidth: "250px",
                          }}
                          className="name"
                        >
                          <img
                            src={process.env.REACT_APP_ENDPOINT + item?.avatar}
                            alt=""
                            height="50px"
                            width="50px"
                            style={{
                              marginRight: "15px",
                              borderRadius: "50%",
                            }}
                          />
                          <Translate>{item?.name}</Translate>
                        </td>
                        <td>{moment(item?.birthday).format("DD/MM/YYYY")}</td>
                        <td>
                          <Translate>{item?.address}</Translate>
                        </td>
                        <td>
                          <Translate>{item?.infoClub?.name}</Translate>
                        </td>
                        <td>
                          <Translate>{item?.branch?.name}</Translate>
                        </td>
                        <td
                          className="button-group"
                          style={{ verticalAlign: "middle" }}
                        >
                          {/* <button
                            style={{
                              border: "0.5px solid #0E2431",
                              minWidth: "150px",
                            }}
                            // onClick={() => handlePrintDetail(item)}
                          >
                            <CSVLink
                              className="mb-2"
                              data={dataMember}
                              filename={`${item?.name}.csv`}
                              style={{ color: "#0E2431" }}
                            >
                              <Translate>{t("search.export")}</Translate>
                            </CSVLink>
                          </button> */}

                          <button
                            style={{
                              border: "0.5px solid #0E2431",
                              margin: "0",
                              minWidth: "150px",
                              color: "#0E2431",
                            }}
                            onClick={() => handleOpenDetail(item)}
                          >
                            <a style={{ color: "#0E2431" }}>
                              <Translate>{t("search.detail")}</Translate>
                            </a>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
              <div className="mb-2 d-flex justify-content-center">
                <Pagination defaultCurrent={1} total={data.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Translator>
  );
};

export default List;
