import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/category-document/category-document.scss";
import vectorNext from "../../assets/images/home-update/vector-next.svg";
import vectorPreview from "../../assets/images/home-update/vector-preview.svg";
import arrowRight from "./../../assets/images/icons/arrow_right_noline.svg";
import {
  URL_API_HISTORY_DETAIL,
  URL_API_LIST_HISTORY,
} from "../../utils/constants";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDataByID, getDataByParams } from "../../utils/service";
import { Translator, Translate } from "react-auto-translate";
import ReactHtmlParser from "react-html-parser";

import Breadcrumb from "../../components/BreadcrumbCommon";
import BannerCommon from "../../components/BannerCommon";

const HistoryUpdate = () => {
  const { id } = useParams();
  const [dataDetailHistory, setDataDetailHistory] = useState({});
  const [contentDetail, setContentDetail] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getDataByParams(URL_API_LIST_HISTORY)
      .then((res) => {
        setDataDetailHistory(res?.data?.[0] || []);
        setContentDetail(res?.data?.[0]?.content || "");
      })
      .catch((err) => {
        return err;
      });
  }, []);

  // useEffect(() => {
  //   getDataDetailHistory();
  // }, [id]);

  // const getDataDetailHistory = () => {
  //   try {
  //     if (id) {
  //       getDataByID(URL_API_HISTORY_DETAIL, id).then((res) => {
  //         setDataDetailHistory(res?.data);
  //         setContentDetail(res?.data?.content || "");
  //       });
  //     }
  //   } catch (error) {
  //     setDataDetailHistory({});
  //   }
  // };
  //Tam thoi comment lai so vs ban dau
  // const convertDataHtml = (data) => {
  //   const re = /<.*?[>]*>/gi;
  //   const result = data.match(re);
  //   const converted_result = [];
  //   if (result?.length > 0) {
  //     let _tmps = [];
  //     let _tmps_only_text = [];
  //     for (let i = 0; i < result.length - 1; i++) {
  //       let start = data.indexOf(result[i]);
  //       let _start = start + result[i].length;
  //       let end = data.indexOf(result[i + 1]);
  //       let _end = data.indexOf(result[i + 1]);
  //       if (start === end) {
  //         _end = data.indexOf(result[i + 1], 1);
  //         end = data.indexOf(result[i + 1], 1) + result[i + 1].length;
  //       } else {
  //         end = end + result[i + 1].length;
  //       }
  //       let tmp = data.substring(start, end);
  //       _tmps.push(tmp);
  //       let only_text = data.substring(_start, _end);
  //       if (only_text !== "" && only_text !== "&nbsp;" && only_text === " ") {
  //         _tmps_only_text.push(only_text);
  //       } else {
  //         _tmps_only_text.push(only_text.replace("&nbsp;", ""));
  //       }
  //       data = data.substring(end - result[i + 1].length);
  //     }

  //     for (let i = 0; i < result.length - 1; i++) {
  //       if (i > 0) {
  //         converted_result.push({
  //           beforeTag: "",
  //           content: _tmps_only_text[i],
  //           afterTag: result[i + 1],
  //         });
  //       } else {
  //         converted_result.push({
  //           beforeTag: result[i],
  //           content: _tmps_only_text[i],
  //           afterTag: result[i + 1],
  //         });
  //       }
  //     }
  //   }
  //   return converted_result;
  // };

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <div className="category-document">
      {/* <div className="container-1200"> */}
      <div className="category-document__banner">
        <BannerCommon text1={t("history.title")} />
      </div>
      {/* <div className="banner-category-document"> */}
      {/* <div className="banner-category-document__header">
            {t("common.history")}
          </div> */}
      {/* <ul className="banner-category-document__breadcrumb">
            <li>
              <span>
                <Link to="/">
               
                  {t("common.home")}
                 
                </Link>
              </span>
            </li>
            <li>
              <span>
              
                {t("common.history")}
              
              </span>
            </li>
          </ul> */}
      {/* </div> */}
      {/* <p className="title-category-document">{dataDetailHistory.title}</p> */}
      <div className="container">
        <Breadcrumb
          dataBreadcrumb={[
            { title: t("history.home"), url: "/" },
            { title: t("history.title") },
          ]}
        />

        <div className="content-category-document">
          <p
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: dataDetailHistory?.content }}
          ></p>
          {/* {convertDataHtml(contentDetail)?.map((itemHtml) => {
              const content = <Translate>{itemHtml.content}</Translate>;
              return (
                <>
                <div
                className="ck-content"
                dangerouslySetInnerHTML={{ __html: itemHtml.beforeTag }}
                ></div>
                {content}
                <div
                className="ck-content"
                dangerouslySetInnerHTML={{ __html: itemHtml.afterTag }}
                ></div>
                </>
                );
                })} */}
        </div>
      </div>
      {/* <div className="block-next-preview-document">
            <div className="block-next-preview">
              <div className="block-next-preview__left">
                {dataDetailHistory?.prev && (
                  <Link to={`/history/${dataDetailHistory?.prev?.id}`}>
                    <img src={vectorPreview} alt="" />
                    <Translate>{dataDetailHistory?.prev?.title}</Translate>
                  </Link>
                )}
              </div>
              <div className="block-next-preview__right">
                {dataDetailHistory?.next && (
                  <Link to={`/history/${dataDetailHistory?.next?.id}`}>
                    <Translate>{dataDetailHistory?.next?.title}</Translate>
                    <img src={vectorNext} alt="" />
                  </Link>
                )}
              </div>
            </div>
          </div> */}
      {/* </div> */}
    </div>
    // </Translator>
  );
};

export default HistoryUpdate;
