export const SEARCH_ACTION_TYPE = {
  SET_DATA: "SET_DATA_SEARCH",
};
const defaultState = {
  loading: false,
  data: [],
  paginate: null,
  keyword: "",
};
const reducers = (state = defaultState, actions) => {
  switch (actions.type) {
    case SEARCH_ACTION_TYPE.SET_DATA: {
      return actions.payload;
    }

    default:
      return state;
  }
};
export default reducers;
