import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { Button } from "antd";

import TitleLine from "../../components/TitleLine";
import Button1 from "../../components/Button";
import NewBlock from "../Home/NewsBlock";
import {
  URL_API_LIBRARY,
  CATALOG_LIBRARY,
  TEXT_ERROR_API,
} from "../../utils/constants";
import { postData } from "../../utils/service";
import DefaultImagePost from "../../assets/images/default_post_image.png";

const PER_PAGE_POSTS = 9;
// const DOCUMENT_TYPE = [
//   { id: 4, name: "Tư liệu Phân đường" },
//   { id: 5, name: "Tư liệu Hệ phái" },
//   { id: 6, name: "Tư liệu Karate" },
//   { id: 7, name: "Tư liệu Võ thuật" },
//   { id: 1, name: "Bài viết của thầy Dũng" },
//   { id: 2, name: "Bài viết của Huyền đai" },
//   { id: 3, name: "Bài viết của Môn sinh" },
//   { id: 8, name: "Bài viết khác" },
// ];

const Posts = ({ tabId }) => {
  const [buttonActive, setButtonActive] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [documentTypeId, setDocumentTypeId] = useState(null);

  const category = [
    {
      id: 1,
      name: t("posts.all"),
      key: "all",
      documentType: 0,
    },
    {
      id: 2,
      name: t("posts.phanDuong"),
      key: "document-distribution",
      documentType: 4,
    },
    {
      id: 3,
      name: t("posts.karate"),
      key: "document-karate",
      documentType: 6,
    },
    {
      id: 4,
      name: t("posts.voThuat"),
      key: "martial-arts",
      documentType: 7,
    },
    {
      id: 5,
      name: t("posts.huyenDai"),
      key: "belt",
      documentType: 2,
    },
    {
      id: 6,
      name: t("posts.monSinh"),
      key: "disciple",
      documentType: 3,
    },
    {
      id: 7,
      name: t("posts.voSu"),
      key: "boss",
      documentType: 1,
    },
  ];

  const [posts, setPosts] = useState({
    data: [],
    category: category[0].key,
    hasMore: true,
    paginate: null,
  });
  // Data Post
  const DATA_POST_POSTS = {
    per_page: PER_PAGE_POSTS,
    catalog_id: CATALOG_LIBRARY(tabId),
    keyword: "",
    document_type: null,
  };
  //current Button
  const handleButton = (index, documentType) => {
    setButtonActive(index);
    setDocumentTypeId(documentType);
  };
  // Load more post
  const loadMore = () => {
    if (posts.paginate.next) {
      setLoadingMore(true);
      postData(URL_API_LIBRARY, {
        ...DATA_POST_POSTS,
        page: posts.paginate.next,
      })
        .then((res) => {
          const { data, paginate } = res.data;
          setPosts({
            hasMore: paginate.next ? true : false,
            data: data.length
              ? [...posts.data, ...convertDataPosts(data)]
              : [...posts.data],
            paginate,
          });
          setLoadingMore(false);
        })
        .catch(() => alert(TEXT_ERROR_API));
    }
  };
  // Convert Data
  const convertDataPosts = (data) => {
    let dataConvert = [];
    if (data.length !== 0) {
      dataConvert = data.map((post) => {
        const image = post.attach_files[0]
          ? post.thumbnail
            ? post.thumbnail
            : post.attach_files[0].url
          : null;
        return {
          id: post.id,
          image,
          title: post.title,
          author: post.user.name,
          time: post.created_at,
        };
      });
    }
    return dataConvert;
  };
  // Fetch Data First
  const fetchDataPost = () => {
    setLoading(true);
    postData(URL_API_LIBRARY, {
      ...DATA_POST_POSTS,
      document_type: documentTypeId ? documentTypeId : null,
    })
      .then((res) => {
        const { data, paginate } = res.data;
        setLoading(false);
        setPosts({
          hasMore: paginate.next ? true : false,
          data: data.length ? convertDataPosts(data) : [],
          paginate,
        });
      })
      .catch(() => alert(TEXT_ERROR_API));
  };

  useEffect(() => {
    fetchDataPost();
  }, [documentTypeId]);

  // const isHavePosts = posts.data.length !== 0;
  return (
    <section className="library_posts" id="docs">
      <TitleLine title={{ name: t("common.posts") }} />
      <div className="library_posts-category">
        {category &&
          category.map((item, index) => {
            return (
              <Button
                key={index}
                className={buttonActive === index ? "current" : ""}
                onClick={() => handleButton(index, item.documentType)}
                htmlType="button"
              >
                {item.name}
              </Button>
            );
          })}
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div>
          <div className="news_wrapper">
            {posts.data.length ? (
              posts.data.map((post, index) => (
                <NewBlock
                  url_endpoint="library/posts"
                  key={index}
                  news={post}
                  defaultImage={DefaultImagePost}
                />
              ))
            ) : (
              <div className="container technical-active-empty">
                <p className="mb-2 text-center">{t("common.empty")}</p>
              </div>
            )}
          </div>
          {posts.hasMore && (
            <div className="d-flex mt-5">
              <Button1
                text={t("common.loadMore")}
                onClick={loadMore}
                loading={loadingMore}
                type="button"
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Posts;
