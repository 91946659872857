import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import "../../assets/styles/events/events.scss";
import EventItem from "./EventItem";
import { setDataEventsPage } from "../../actions";
import { postData } from "../../utils/service";
import { URL_API_EVENTS, TEXT_ERROR_API } from "../../utils/constants";
import { convertDataEvents } from "../../utils/functions";

import LoadingContainer from "../../components/LoadingContainer";

const PER_PAGE_EVENTS = 10;

const EventsPage = ({ dataEventsPage, setDataEventsPage }) => {
  const [dataEvents, setDataEvents] = useState({
    ...dataEventsPage,
    loading: dataEventsPage.data.length === 0 ? true : false,
  });

  const fetchDataEvent = () => {
    if (!dataEvents.loading)
      setDataEvents({
        ...dataEvents,
        loading: true,
      });
    postData(URL_API_EVENTS, {
      per_page: PER_PAGE_EVENTS,
    })
      .then((res) => {
        const { data, paginate } = res.data;
        const dataSet = {
          loading: false,
          data: convertDataEvents(data),
          paginate: paginate,
          hasMore: paginate.next ? true : false,
        };
        setDataEvents(dataSet);
        // add data to store
        setDataEventsPage(dataSet);
      })
      .catch((err) => {
        alert(TEXT_ERROR_API);
      });
  };
  const fetchMoreData = () => {
    if (dataEvents.paginate && dataEvents.paginate.next) {
      const { total, next } = dataEvents.paginate;
      if (!next) {
        setDataEvents({
          ...dataEvents,
          hasMore: false,
        });
      } else {
        postData(URL_API_EVENTS, {
          per_page: PER_PAGE_EVENTS,
          page: next,
        })
          .then((res) => {
            const { data, paginate } = res.data;
            const allData = [...dataEvents.data, ...convertDataEvents(data)];
            const dataSet = {
              loading: false,
              data: allData,
              paginate: paginate,
              hasMore: allData.length >= total ? false : true,
            };
            setDataEvents(dataSet);
            // add data to store
            setDataEventsPage(dataSet);
          })
          .catch((error) => {
            alert("Something when wrong please refresh page and try again");
          });
      }
    }
  };

  useEffect(() => {
    let mouted = true;
    if (dataEventsPage.data.length === 0) {
      if (mouted) fetchDataEvent();
    }
    return () => {
      mouted = false;
    };
  }, []);

  if (dataEvents.loading || dataEvents.data.length === 0)
    return <LoadingContainer />;

  return (
    <section className="events_page">
      <InfiniteScroll
        hasMore={dataEvents.hasMore}
        dataLength={dataEvents.data.length}
        className="events_wrapper container"
        next={fetchMoreData}
      >
        {dataEvents.data.length !== 0 &&
          dataEvents.data.map((event, index) => (
            <EventItem event={event} key={index.toString()} />
          ))}
      </InfiniteScroll>
      {dataEvents.hasMore ? <Spinner animation="border" /> : null}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    dataEventsPage: state.eventspage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDataEventsPage: (data) => dispatch(setDataEventsPage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
