import React from "react";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import DefaltImage from "../../assets/images/default_image.svg";

import Icon_play from "../../assets/images/icons/play_button.svg";
const VideoBlock = ({ video, selectVideo }) => {
  const { t } = useTranslation();
  const { poster, title, author, time_updated, url_video } = video;

  const PosterVideo = poster
    ? process.env.REACT_APP_ENDPOINT + poster
    : DefaltImage;

  return (
    <div className="video_block fade-in" onClick={() => selectVideo(video)}>
      <div className="video_block-poster">
        <LazyLoad offset={100}>
          <img src={PosterVideo} alt={title} />
        </LazyLoad>
        <img className="icon_play" src={Icon_play} alt="play video" />
      </div>
      <div className="video_block-content">
        <h3 className="title_block">{title}</h3>
        <div className="time_author_wrapper">
          <p className="time_author_text text_view_time">
            <span>
              {t("common.by")} {author}
            </span>
            <span>{time_updated}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;
