import React from "react";
import { checkError } from "../../helpers/error";

const Select = React.forwardRef((props, ref) => {
  const {
    errors,
    data,
    key_value,
    key_label,
    label,
    include_blank,
    defaultValue,
    row,
    ...input
  } = props;
  return (
    <div className={checkError(errors, input.name)}>
      {label && <label className="input-label">{label}</label>}
      <select {...input} ref={ref}>
        {defaultValue ? (
          <option value={defaultValue[key_value]}>
            {defaultValue[key_label]}
          </option>
        ) : (
          include_blank && <option value="">{include_blank}</option>
        )}
        {data.map((item, index) => {
          return defaultValue ? (
            item[key_label] != defaultValue[key_label] && (
              <option key={index} value={item[key_value]}>
                {item[key_label]}
              </option>
            )
          ) : (
            <option key={index} value={item[key_value]}>
              {item[key_label]}
            </option>
          );
        })}
      </select>
    </div>
  );
});

export default Select;
