import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import ArrowRight from "./../../assets/images/icons/arrow_right_noline.svg";

import "./../BreadcrumbCommon/BreadcrumbCommon.scss";

function BreadcrumbComponent({ dataBreadcrumb }) {
  return (
    <Breadcrumb separator={<img src={ArrowRight} alt="" />}>
      {dataBreadcrumb?.map((data, index) => (
        <Breadcrumb.Item key={index} overlay={data?.menu}>
          {data.url ? <Link to={data?.url}>{data?.title}</Link> : data?.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default BreadcrumbComponent;
