import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/category-document/category-document.scss";
import iconList from "./../../assets/images/home-update/icon-list.svg";
import vectorNext from "../../assets/images/home-update/vector-next.svg";
import vectorPreview from "../../assets/images/home-update/vector-preview.svg";
import { useParams } from "react-router-dom";
import {
  URL_API_BANNERS,
  URL_API_INFO_TYPE_DOCUMENT,
} from "../../utils/constants";
import { postData } from "../../utils/service";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { Translator, Translate } from "react-auto-translate";
import BannerCommon from "../../components/BannerCommon";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";

const CategoryDocument = () => {
  const { idCategory } = useParams();
  const [dataInfoDocumentType, setDataInfoDocumentType] = useState({});
  const [dataListItemDocument, setDataListItemDocument] = useState([]);
  const [paginateInfoDocumentType, setPaginateInfoDocumentType] = useState({});
  const [banner, setBanner] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    getInforDocumentType();
    getBanner();
  }, [idCategory]);

  const getBanner = () => {
    postData(URL_API_BANNERS, {
      is_show: "true",
      type: "LIBRARY",
    })
      .then((res) => {
        setBanner(res?.data?.data?.[0]?.image);
      })
      .catch((err) => {
        return err;
      });
  };

  const getInforDocumentType = (pageDocumentType = 1) => {
    try {
      postData(URL_API_INFO_TYPE_DOCUMENT, {
        per_page: 5,
        document_type: idCategory,
        page: pageDocumentType,
      }).then((res) => {
        setDataInfoDocumentType(res?.data?.data);
        setDataListItemDocument(res?.data?.relatives);
        setPaginateInfoDocumentType(res?.data?.paginate);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePagination = (page) => {
    getInforDocumentType((page = page));
  };

  const convertDataHtml = (data) => {
    const re = /<.*?[>]*>/gi;
    const result = data.match(re);
    const converted_result = [];
    if (result?.length > 0) {
      let _tmps = [];
      let _tmps_only_text = [];
      for (let i = 0; i < result.length - 1; i++) {
        let start = data.indexOf(result[i]);
        let _start = start + result[i].length;
        let end = data.indexOf(result[i + 1]);
        let _end = data.indexOf(result[i + 1]);
        if (start === end) {
          _end = data.indexOf(result[i + 1], 1);
          end = data.indexOf(result[i + 1], 1) + result[i + 1].length;
        } else {
          end = end + result[i + 1].length;
        }
        let tmp = data.substring(start, end);
        _tmps.push(tmp);
        let only_text = data.substring(_start, _end);
        if (only_text !== "" && only_text !== "&nbsp;") {
          _tmps_only_text.push(only_text);
        } else {
          _tmps_only_text.push(only_text.replace("&nbsp;", " "));
        }
        data = data.substring(end - result[i + 1].length);
      }

      for (let i = 0; i < result.length - 1; i++) {
        if (i > 0) {
          converted_result.push({
            beforeTag: "",
            content: _tmps_only_text[i],
            afterTag: result[i + 1],
          });
        } else {
          converted_result.push({
            beforeTag: result[i],
            content: _tmps_only_text[i],
            afterTag: result[i + 1],
          });
        }
      }
    }
    return converted_result;
  };

  const breadCrumb = [
    {
      title: t("library.home"),
      url: "/",
    },
    {
      title: t("library.library"),
      url: "/document",
    },
    {
      title: dataInfoDocumentType?.title,
    },
  ];

  return (
    // <Translator
    //   from='vi'
    //   to={localStorage.getItem('to')}
    //   googleApiKey='AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc'
    // >
    <div className="wrapDocument">
      <div className="wrapDocument__banner">
        <BannerCommon text1={dataInfoDocumentType?.title} />
      </div>
      <div className=" category-document container">
        <BreadcrumbComponent dataBreadcrumb={breadCrumb} />
        <div className="content-category-document">
          <div className="content-main">
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: dataInfoDocumentType?.content,
              }}
            ></div>
          </div>
          <div className="block-list-document">
            <p className="title">
              {/* <Translate> */}
              Các bài viết
              {/* </Translate> */}
            </p>
            <hr className="line" />
            <ul>
              {dataListItemDocument?.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="block-list-document__item">
                      <span>
                        <img className="icon-list" src={iconList} />
                        {/* <Translate> */}
                        <p>{item?.title}</p>
                        {/* </Translate> */}
                      </span>
                      <Link
                        className="see-detail"
                        to={`/document/${item?.document_type}/detail/${item?.id}`}
                      >
                        {/* <Translate> */}
                        Xem chi tiết
                        {/* </Translate> */}
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ul>
            {paginateInfoDocumentType?.count > 5 && (
              <Pagination
                className="pagination-home-page pagination-home-page--custom"
                current={paginateInfoDocumentType?.currentPage}
                pageSize={5}
                onChange={onChangePagination}
                total={paginateInfoDocumentType?.count}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    // </Translator>
  );
};

export default CategoryDocument;
