import React from "react";
import LazyLoad from "react-lazyload";

const Book = ({ book }) => {
  const { name, image, url } = book;

  return (
    <a
      href={process.env.REACT_APP_ENDPOINT + url}
      className="book_item fade-in"
    >
      <LazyLoad>
        <img alt="" src={image} />
      </LazyLoad>
      <p>{name}</p>
      {/* <span>{author}</span> */}
    </a>
  );
};

export default Book;
