import React, { useState } from "react";
import InputMask from "react-input-mask";
import { checkError } from "../../helpers/error";

const InputDate = React.forwardRef((props, ref) => {
  const { errors, defaultValue, id, ...input } = props;

  const [value, setValue] = useState({
    value: defaultValue || "",
    mask:
      defaultValue && defaultValue.length > 14 ? "dd/mm/yyyy" : "dd/mm/yyyy",
  });

  React.useEffect(() => {
    setValue({
      value: defaultValue || "",
      mask:
        defaultValue && defaultValue.length > 14 ? "dd/mm/yyyy" : "dd/mm/yyyy",
    });
  }, [defaultValue]);

  const onChange = (e) => {
    const val = e.target.value;

    const newState = {
      mask: "dd/mm/yyyy",
      value: val,
    };

    if (val.length > 14) {
      newState.mask = "dd/mm/yyyy";
    }

    setValue(newState);
  };

  return (
    <div className={checkError(errors, input.name)}>
      {input.label && <label className="input-label">{input.label}</label>}
      <InputMask
        id={id}
        {...value}
        onChange={onChange}
        maskPlaceholder={null}
        {...input}
      />
      <input type="hidden" ref={ref} value={value.value} />
    </div>
  );
});

export default InputDate;
