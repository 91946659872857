import React from "react";

import { Input } from "antd";

import "./../Input/input-common.scss";

const InputCommon = ({ label, isRequired = false, ...rest }) => {
  return (
    <div className="wrap-input">
      {label && (
        <span className="label">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </span>
      )}
      <Input {...rest} />
    </div>
  );
};

export default InputCommon;
