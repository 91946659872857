import React, { useState, useEffect } from "react";
import { Player } from "video-react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Button1 from "../../components/Button";
import VideoBlock from "./VideoBlock";
import TitleLine from "../../components/TitleLine";

import { URL_API_LIBRARY, CATALOG_LIBRARY } from "../../utils/constants";
import { postData } from "../../utils/service";

const PER_PAGE_VIDEO = 10;
const VideoContent = ({ tabId }) => {
  const { t } = useTranslation();
  const [fluidVideo, setFluidVideo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState({
    currentPlay: null,
    hasMore: true,
    loading: false,
    data: [],
    paginate: null,
  });
  // Data Post
  const DATA_POST_VIDEO = {
    per_page: PER_PAGE_VIDEO,
    catalog_id: CATALOG_LIBRARY(tabId),
    keyword: "",
  };
  // Convert data
  const convertDataVideo = (data) => {
    let dataConvert = [];
    if (data.length !== 0) {
      dataConvert = data.map((video) => ({
        id: video.id,
        poster: video.thumbnail,
        title: video.title,
        author: video.user.name && video.user.name,
        src: video.attach_files[0] ? video?.attach_files[0]?.url : null,
        time: video.created_at,
        time_updated: video.time_updated,
        created_at: video.created_at,
      }));
    }
    return dataConvert;
  };
  // Scroll to current Video
  const scrollToVideo = () => {
    document.querySelector("#video").scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  // Select video
  const selectVideo = (selectedVideo) => {
    setVideos({
      ...videos,
      currentPlay: selectedVideo,
    });
    scrollToVideo();
  };
  // Load more
  const loadMore = () => {
    if (videos.paginate.next) {
      if (!videos.loading)
        setVideos({
          ...videos,
          loading: true,
        });
      postData(URL_API_LIBRARY, {
        ...DATA_POST_VIDEO,
        page: videos.paginate.next,
      }).then((res) => {
        const { data, paginate } = res.data;
        setVideos({
          ...videos,
          isLoadmore: false,
          hasMore: paginate.next ? true : false,
          data: [...videos.data, ...convertDataVideo(data)],
          paginate,
        });
      });
    }
  };
  // Fetch Data first time
  const fetchDataVideos = () => {
    if (!loading) setLoading(true);
    postData(URL_API_LIBRARY, {
      ...DATA_POST_VIDEO,
    }).then((res) => {
      const { data, paginate } = res.data;
      const dataVideos = convertDataVideo(data);
      setVideos({
        isLoadmore: false,
        hasMore: paginate.next ? true : false,
        data: dataVideos,
        paginate,
        currentPlay: dataVideos[0],
      });
      if (loading) setLoading(false);
    });
  };
  useEffect(() => {
    if (window.innerWidth <= 1140) {
      setFluidVideo(true);
    }
  }, []);
  useEffect(() => {
    let mounted = true;
    if (mounted) fetchDataVideos();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <section className="library_videos" id="videos">
      <TitleLine title={{ name: "VIDEO" }} />
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {videos.currentPlay && (
            <div id="video">
              <Player
                height={500}
                width={1110}
                preload={"none"}
                poster={
                  process.env.REACT_APP_ENDPOINT + videos.currentPlay.poster
                }
                playsInline={true}
                fluid={fluidVideo}
                src={process.env.REACT_APP_ENDPOINT + videos.currentPlay.src}
                className="fade-in"
              ></Player>
            </div>
          )}

          <div className="video_block current fade-in">
            <div className="video_block-content">
              <h3 className="title_block">{videos.currentPlay.title}</h3>

              <div className="time_author_wrapper">
                <p className="time_author_text text_view_time">
                  <span>
                    {t("common.by")} {videos.currentPlay.author}
                  </span>
                  <span>{videos.currentPlay.created_at}</span>
                </p>
              </div>
            </div>
          </div>
          {videos.data.length !== 0 && (
            <div className="library_videos-list">
              {videos.data.map((video, index) => {
                if (video.id !== videos.currentPlay.id) {
                  return (
                    <VideoBlock
                      video={video}
                      key={index.toString()}
                      selectVideo={selectVideo}
                    />
                  );
                } else return null;
              })}
            </div>
          )}
          {videos.hasMore && (
            <div className="d-flex mt-5">
              <Button1
                text={t("common.loadMore")}
                onClick={loadMore}
                loading={videos.loading}
                type="button"
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default VideoContent;
