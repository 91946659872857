import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DefaultImageAssets from "../../assets/images/default_image.svg";
import { Translator, Translate } from "react-auto-translate";

const NewsBlock = ({ news, url_endpoint, defaultImage }) => {
  const { t } = useTranslation();
  const { title, image, author, time, id } = news;

  const URL_REDIRECT = url_endpoint ? url_endpoint : "news";
  const IMAGE_BLOCK = image
    ? process.env.REACT_APP_ENDPOINT + image
    : defaultImage
    ? defaultImage
    : DefaultImageAssets;

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <Link to={`/${URL_REDIRECT}/${id}`} className="news_block fade-in">
      <div>
        <div
          className="news_block-img"
          style={{ backgroundImage: `url(${IMAGE_BLOCK})` }}
        />

        <div className="news_block-content">
          <p className="">
            {/* <Translate> */}
            {t("common.by")} {author} | {time}
            {/* </Translate> */}
          </p>
          <h2 className="news_block-content-title">
            {/* <Translate> */}
            {title}
            {/* </Translate> */}
          </h2>
        </div>

        <p className="news_block-button-more d-flex align-items-center text-danger">
          <span className="mr-2 text-capitalize">
            {/* <Translate> */}
            {t("tech-active.detail")}
            {/* </Translate> */}
          </span>
          <ArrowRightOutlined style={{ fontSize: 12, marginTop: 2 }} />
        </p>
      </div>
    </Link>
    // </Translator>
  );
};

export default NewsBlock;
