import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Translate } from 'react-auto-translate';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { URL_API_BRANCH_IN_AREA } from '../../utils/constants';
import { getTakenData } from '../../utils/service';
import { setScrollPage } from './../../actions/index';

import imgMap from './../../assets/images/home-update/ban-do-hs-ts.png';
import iconNextWhite from './../../assets/images/home-update/icon-next-white.svg';
import imgClub from './../../assets/images/home-update/item-activity.png';
import forward from './../../assets/images/icons/press.svg';
const { SubMenu } = Menu;

const SectionClub = ({ path }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2
  };
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [openKeys, setOpenKeys] = useState(['']);
  const [data, setData] = useState([]);
  const [rootMenuKeys, setRootMenuKeys] = useState([]);

  const getDataClub = () => {
    try {
      getTakenData(URL_API_BRANCH_IN_AREA).then((res) => {
        const dataTmp = res?.data?.map((item) => {
          return item?.name;
        });
        setRootMenuKeys(dataTmp);
        setData(res?.data);
      });
    } catch (error) {
      setData([]);
    }
  };
  console.log(data, rootMenuKeys);
  useEffect(() => {
    getDataClub();
  }, []);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const redirectClubDetail = (id) => {
    history.push(`/club/${id}`);
  };

  useEffect(() => {
    if (history?.location?.search) {
      const search = new URLSearchParams(history?.location?.search);
      const id = search?.get('id');
      const activeKey = data?.find((item) =>
        item?.branchs?.find((itemChild) => itemChild?.id === +id)
      )?.id;
      setOpenKeys([`${activeKey}`]);
      const club = document?.getElementById('section-club');
      club.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(setScrollPage('section-club'));
    }
  }, [history?.location?.search, data?.length]);

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <section className="section-club-home" id="section-club">
      <div className="block-title-main__title">
        <hr className="block-title-main__line block-title-main__line--cl-white" />
        <p className="block-title-main__title-main">
          <Translate>{t('home-page.title-club')}</Translate>
        </p>
      </div>
      <div className="block-item-club row">
        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className="
              col-10
              col-sm-6 col-md-4 block-item-club__content"
              id={`activity-${item.id}`}
            >
              <div className="block-item-club__content--image">
                <img
                  src={
                    item.image
                      ? process.env.REACT_APP_ENDPOINT + item?.image
                      : imgClub
                  }
                  alt={item?.name}
                />
              </div>
              <div className="block-item-club__content--title">
                <p>{item?.name}</p>
              </div>
              <div className="block-item-club__content--link">
                <Link
                // to={`/club/${item?.id}`}
                >
                  <Translate>{t('common.viewDetail')}</Translate>
                  <img src={forward} alt="forward" />
                </Link>
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="section-club-home__left">
        <img className="img-map" src={imgMap} alt="" />
      </div>
      <div className="section-club-home__right">
        <p className="title">
          <Translate>{t("home-page.title-club")}</Translate>
        </p>
        <Menu
          className="menu-club"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          
            return (
              <SubMenu
                expandIcon={
                  <img className="icon-next" src={iconNextWhite} alt="" />
                }
                key={item?.id}
                title={
                  <div className="title-list-club">
                    <Translate>{item?.name}</Translate>
                  </div>
                }
              >
                {item?.branchs?.map((itemBrand) => {
                  return (
                    <Menu.Item key={itemBrand?.name}>
                      <div
                        className="title-list-club__sub"
                        onClick={() => redirectClubDetail(itemBrand.id)}
                      >
                        <Translate>{itemBrand?.name}</Translate>
                      </div>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
        </Menu>
      </div> */}
    </section>
    // </Translator>
  );
};

export default SectionClub;
