import React from "react";

import "../../assets/styles/detail-search/detail-item.scss";

import DefaultImageAssets from "./../../assets/images/default_image.svg";

const DetailItem = ({ data, handleViewDetail }) => {
  function isImageFile() {
    const extension = data?.attach_files[0]?.split(".")?.pop()?.toLowerCase();

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    return imageExtensions?.includes(extension);
  }

  return (
    <div
      className='item'
      onClick={() => handleViewDetail(data?.menu_id || data?.id)}
    >
      <div className='item__left'>
        <img
          src={
            isImageFile()
              ? process.env.REACT_APP_ENDPOINT + data?.attach_files[0]
              : DefaultImageAssets
          }
          alt=''
        />
      </div>
      <div className='item__right'>
        <p className='item__right--title'>{data?.title}</p>
        {data?.description && (
          <p
            className='item__right--content content-custom'
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        )}

        <p className='item__right--more'>Đọc Tiếp</p>
      </div>
    </div>
  );
};

export default DetailItem;
