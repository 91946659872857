import React, { useState, useEffect } from "react";
import {
  URL_API_BANNERS,
  URL_API_NEWS,
  URL_WEB_NEWS,
} from "./../../utils/constants";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import { postData } from "../../utils/service";
import Pagination from "./../../components/pagination";
import CardInformationCommon from "./../../components/CardInformationCommon/index";
import Breadcrumb from "./../../components/BreadcrumbCommon/index";
import BannerCommon from "../../components/BannerCommon";

import "./../../assets/styles/information/information.scss";

const Information = () => {
  const { t } = useTranslation("translation");

  const [dataInformation, setDataInformation] = useState([]);
  const [imageBanner, setImageBanner] = useState("");
  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 12,
    total: 0,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    postData(URL_API_NEWS, {
      per_page: params?.pageSize,
      page: params?.currentPage,
    })
      .then((res) => {
        setDataInformation(res?.data?.data);
        setParams({ ...params, total: res?.data?.paginate?.total });
      })
      .catch((err) => {
        return err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.currentPage]);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "INFORMATION"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleChangeCurrentPage = (e) => {
    setParams({ ...params, currentPage: e });
  };

  return (
    <>
      <div className="information">
        <div className="information__banner">
          <BannerCommon img={imageBanner} text1={t("information.title")} />
        </div>
        <div className="container">
          <div className="container__breadcrumb">
            <Breadcrumb
              dataBreadcrumb={[
                { title: t("information.home"), url: "/" },
                { title: t("information.title-detail") },
              ]}
            />
          </div>
          <div className="container__content">
            <Row
              gutter={[{ xs: 8, lg: 16, sm: 16, xl: 24, md: 16, xxl: 24 }, 24]}
            >
              {dataInformation?.length > 0 &&
                dataInformation?.map((item) => {
                  return (
                    <Col xs={24} md={12} lg={8} xl={6} key={item?.id}>
                      <CardInformationCommon
                        data={{
                          image:
                            item?.thumbnail ||
                            item?.image ||
                            item?.attach_files?.[0],
                          title: item?.title,
                          description: item?.description,
                          id: item?.id,
                          date: item?.created_at,
                          path: URL_WEB_NEWS,
                        }}
                      />
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div className="container__pagination">
            <Pagination
              current={params?.currentPage}
              pageSize={params?.pageSize}
              total={params?.total}
              onChange={handleChangeCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Information;
