import React, { useState, useEffect } from "react";
import { URL_API_BRANCHS, URL_API_BRANCH_IN_AREA } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDataByID, getTakenData } from "../../utils/service";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";

import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import CardClub from "../../components/CardClub";
import BannerCommon from "../../components/BannerCommon";

import "./../ClubInArea/ClubInArea.scss";

const ClubInArea = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [dataClubGroup, setDataClubGroup] = useState([]);
  const [nameClubGroup, setNameClubGroup] = useState("");
  const [dataInArea, setDataInArea] = useState([]);
  const [imageBanner, setImageBanner] = useState("");

  const menu = (
    <Menu>
      {dataClubGroup?.map((data) => {
        return (
          <Menu.Item key={data?.id}>
            <Link to={`/branchsinareas/${data?.id}`}>{data?.name}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const dataBreadcrumbs = [
    { title: t("club-list.home"), url: "/" },
    {
      title: t("club-list.header"),
      url: "/branchsinareas",
      menu: menu,
    },
    { title: nameClubGroup },
  ];

  const getDataClubGroup = () => {
    try {
      getTakenData(URL_API_BRANCH_IN_AREA).then((res) => {
        const dataClubGroup = res.data.find((item) => item.id === Number(id));
        setDataClubGroup(res.data);
        setNameClubGroup(dataClubGroup.name);
        const imageByType = res?.data?.find((item) => item.id === Number(id));
        setImageBanner(imageByType.image);
      });
    } catch (error) {
      setNameClubGroup("");
    }
  };

  useEffect(() => {
    getDataClubGroup();
  }, [id]);
  const handleClick = (id) => {
    history.push(`/club/${id}`);
  };

  useEffect(() => {
    getDataInArea();
  }, [id]);

  const getDataInArea = () => {
    try {
      if (id) {
        getDataByID(URL_API_BRANCHS, id).then((res) => {
          setDataInArea(res?.data);
        });
      }
    } catch (error) {
      setDataInArea([]);
    }
  };

  return (
    <>
      <BannerCommon img={imageBanner} text1={nameClubGroup} />
      <div className="container">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />
        <div className="content row">
          {dataInArea.map((item) => (
            <div
              key={item.id}
              id={item.id}
              className="col-12 col-sm-6 col-md-4 col-lg-3 content__card"
            >
              <CardClub
                data={item}
                isButton={false}
                handleClick={handleClick}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ClubInArea;
