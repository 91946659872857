import React from "react";
import { Button } from "antd";
import { Spinner } from "react-bootstrap";

const Button1 = ({ icon, text, onClick, type, loading, nameClass, styles }) => {
  return (
    <Button
      className={`button_type_1 m-auto ${nameClass && nameClass}`}
      htmlType={type}
      onClick={onClick}
      style={styles}
    >
      {loading ? <Spinner animation="border" /> : text}
    </Button>
  );
};

export default Button1;
