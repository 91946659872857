import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  URL_API_NEWS_DETAIL,
  URL_API_NEWS_RELATE,
  URL_WEB_NEWS,
} from "../../utils/constants";
import { getTakenData } from "../../utils/service";

import BannerCommon from "../../components/BannerCommon";
import Breadcrumb from "../../components/BreadcrumbCommon";
import SliderCommon from "../../components/SliderCommon";
import CardAuthor from "../../components/CardAuthor";
import HashtagCommon from "../../components/Hashtag";

import "../../assets/styles/category-document/category-document.scss";

const InformationDetail = () => {
  const { t } = useTranslation("translation");
  const { id } = useParams();

  const [dataDetail, setDataDetail] = useState({});
  const [dataRelated, setDataRelated] = useState([]);
  const [imageBanner, setImageBanner] = useState("");

  useEffect(() => {
    if (id) {
      getTakenData(URL_API_NEWS_RELATE(id))
        .then((res) => setDataRelated(res?.data))
        .catch((error) => error);

      getTakenData(URL_API_NEWS_DETAIL(id))
        .then((res) => {
          setDataDetail(res?.data);
          setImageBanner(res?.data?.thumbnail);
        })
        .catch((error) => error);
    }
  }, [id]);

  const dataHashtag = dataDetail?.hashtag;

  return (
    <div className="category-document">
      <div className="category-document__banner">
        <BannerCommon text1={dataDetail.title} img={imageBanner} />
      </div>

      <div className="container">
        <Breadcrumb
          dataBreadcrumb={[
            { title: t("information.home"), url: "/" },
            { title: t("information.title-detail"), url: "/web-news" },
            { title: dataDetail.title },
          ]}
        />
        <CardAuthor
          dataAuthor={{
            image: dataDetail?.user?.avatar,
            name: dataDetail?.user?.name,
            position: dataDetail?.infoBranch,
            date: dataDetail?.updated_at,
          }}
        />
        <div className="content-category-document">
          <p
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: dataDetail?.content }}
          ></p>
        </div>
        <div className="container__hashtag">
          <HashtagCommon dataHashtag={dataHashtag} />
        </div>
        <div className="container__carouselInformation">
          <SliderCommon
            dataRelated={dataRelated}
            path={URL_WEB_NEWS}
            titleHeader={t("information.relate")}
          />
        </div>
      </div>
    </div>
  );
};

export default InformationDetail;
