import { useFormik } from "formik";
import i18n from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import iconEye from "../../assets/images/icons/eye-icon.svg";
import iconOpenDown from "../../assets/images/icons/icon-open-down.svg";
import iconOpenLeft from "../../assets/images/icons/icon-open-left.svg";
import iconOpenRight from "../../assets/images/icons/icon-open-right.svg";
import iconOpenUp from "../../assets/images/icons/icon-open-up.svg";
import logo from "../../assets/images/icons/Logo.png";
import InputDate from "../../components/forms/InputDate";
import InputText from "../../components/forms/InputText";
import Select from "../../components/forms/Select";
import { destructServerErrors } from "../../helpers/error";
import {
  GET_LIST_BRANCH,
  GET_LIST_CLUB, URL_API_DISTRICT, URL_API_PROVINCE,
  URL_API_WARD, URL_LOGIN, URL_REGISTER
} from "../../utils/constants";
import { getDataByID, getTakenData, postData } from "../../utils/service";
import RegisterSuccess from "./RegisterSuccess";

export const Alert = (props) => {
  return props.message ? (
    <div className="invalid-feedback" id="invalid-feedback-custom">
      {" "}
      {props.message}{" "}
    </div>
  ) : (
    ""
  );
};



const Register = ({}) => {
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [ward, setWard] = useState([]);
  const [branch, setBranch] = useState([]);
  const [club, setClub] = useState([]);
  const [errors, setErrors] = useState({});
  const [loginErrors, setLoginErrors] = useState({});
  const [checkLanguage, setCheckLanguage] = useState("VI");
  const [checkStatusLanguage, setCheckStatusLanguage] = useState(true);

  const emailRef = useRef("");
  const nameRef = useRef("");
  const genderRef = useRef("");
  const birthdayRef = useRef("");
  const phoneRef = useRef("");
  const provinceIdRef = useRef("");
  const districtIdRef = useRef("");
  const wardIdRef = useRef("");
  const addressRef = useRef("");
  const branchIdRef = useRef("");
  const clubIdRef = useRef("");
  const phoneLoginRef = useRef("");
  const passwordLoginRed = useRef("");

  const { t } = useTranslation();

  const dataGender = [
    {
      id: 1,
      name: t("form.male"),
    },
    {
      id: 2,
      name: t("form.female"),
    },
  ];

  const openForm = () => {
    const formLogin = document.getElementById("form-login");
    formLogin.style.display = "block";
    formLogin.style.opacity = 1;
  };

  const openFormMobile = () => {
    const formRegister = document.getElementById("form-register");
    const formLoginRespon = document.getElementById("form-login-respon");
    formRegister.style.height = "100vh";
    formRegister.style.overflow = "hidden";
    formLoginRespon.style.display = "block";
  };

  const closeForm = () => {
    const formLogin = document.getElementById("form-login");
    formLogin.style.opacity = 0;
    formLogin.style.display = "none";
  };

  const closeFormMobile = () => {
    const formRegister = document.getElementById("form-register");
    const formLoginRespon = document.getElementById("form-login-respon");
    formRegister.style.height = "100%";
    formRegister.style.overflow = "inherit";
    formLoginRespon.style.display = "none";
  };

  const onSubmitRegister = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const gender = genderRef.current.value;
    const birthday = birthdayRef.current.value.split("/").reverse().join("/");
    const phone = phoneRef.current.value;
    const province_id = provinceIdRef.current.value;
    const district_id = districtIdRef.current.value;
    const ward_id = wardIdRef.current.value;
    const address = addressRef.current.value;
    const branch_id = branchIdRef.current.value;
    const club_id = clubIdRef.current.value;

    let formData = {
      email,
      name,
      gender,
      birthday,
      phone,
      province_id,
      district_id,
      ward_id,
      address,
      branch_id,
      club_id,
    };

    postData(URL_REGISTER, formData)
      .then((res) => {
        const form = document.getElementById("register-form");
        const listInput = document.getElementsByClassName("form-group");
        Array.from(listInput).forEach((item) => {
          item.classList.remove("has-errors");
        });
        form.reset();
        const inputDate = document.getElementById("input-date");
        inputDate.value = "";
        const blockSuccess = document.getElementById("block-success");
        blockSuccess.style.zIndex = "100";
        blockSuccess.style.opacity = "1";
        setTimeout(function () {
          blockSuccess.style.zIndex = "-1";
          blockSuccess.style.opacity = "0";
        }, 3000);
      })
      .catch((err) => {
        const errs = destructServerErrors(err?.errors);
        setErrors(errs);
      });
  };
  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required(t("form.validPhone")),
      password: Yup.string().required(t("form.validPassword")),
    }),
    onSubmit: (values) => {
      const phone = phoneLoginRef.current.value.trim();
      const password = passwordLoginRed.current.value;
      const userLogin = {
        phone,
        password,
      };
      postData(URL_LOGIN, userLogin)
        .then((res) => {
          const token = res?.data?.token;
          window.location.href = `${process.env.REACT_APP_ENDPOINT_API_CALLBACK_LOGIN}${token}`;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {
    getListProvince();
    getListBranch();
  }, []);

  const getListProvince = async () => {
    getTakenData(URL_API_PROVINCE).then((res) => {
      setProvince(res?.data?.results);
    });
  };

  const getListBranch = async () => {
    getTakenData(GET_LIST_BRANCH).then((res) => {
      setBranch(res?.data);
    });
  };

  const onChangeProvince = (e) => {
    let target = e.target;
    let value = target.value ? target.value : null;
    getDataByID(URL_API_DISTRICT, value).then((res) => {
      setDistrict(res?.data?.results);
    });
  };

  const onChangeDistrict = (e) => {
    let target = e.target;
    let value = target.value ? target.value : null;
    getDataByID(URL_API_WARD, value).then((res) => {
      setWard(res?.data?.results);
    });
  };

  const onChangeBranch = (e) => {
    let target = e.target;
    let value = target.value ? target.value : null;
    getDataByID(GET_LIST_CLUB, value).then((res) => {
      setClub(res?.data);
    });
  };

  const onChangeLanguage = () => {
    if(checkLanguage === "VI") {
      i18n.changeLanguage("US");
      setCheckLanguage("US");
    } else {
      i18n.changeLanguage("VI");
      setCheckLanguage("VI");
    }
  };

  const showPassword = () => {
    const passwordShow = document.getElementById('password');
    const type = passwordShow.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordShow.setAttribute('type', type);
  }

  return (
    <div className="block-register">
      <RegisterSuccess />
      {/* <Link className="text-url-back" to="/">
        {t("form.back")}
      </Link> */}
      <div className="block-language">
        <div className="block-language__item">
          <input
            id="us-language"
            name="language"
            value="US"
            type="radio"
            onClick={onChangeLanguage}
            checked={checkLanguage === "US"}
          />
          <label htmlFor="us-language">EN</label>
        </div>
        <div className="block-language__item">
          <input
            id="vi-language"
            name="language"
            value="VI"
            type="radio"
            onClick={onChangeLanguage}
            checked={checkLanguage === "VI"}
          />
          <label htmlFor="vi-language">VI</label>
        </div>
      </div>
      <div className="form-main">
        <div className="form-register" id="form-register">
          <div className="content-top">
            <img className="form-register__icon" src={logo} alt="" />
            <h2 className="form-register__title">
              {t("form.welcomeTo")} <br />
              <span className="form-register__title--red">
                {t("form.nameWeb")}
              </span>
            </h2>
          </div>
          <p className="form-register__description">{t("form.description")}</p>
          <form onSubmit={onSubmitRegister} id="register-form">
            <div className="form-row">
              <div className="form-group col-4 col-md-6">
                <InputText
                  className="form-control input-form"
                  name="name"
                  label={t("form.name")}
                  errors={errors}
                  type="text"
                  placeholder={t("form.name")}
                  ref={nameRef}
                />
              </div>
              <div className="form-group col-4 col-md-3">
                <Select
                  label={t("form.gender")}
                  className="form-control"
                  name="gender"
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  data={dataGender}
                  include_blank={t("form.choose")}
                  ref={genderRef}
                />
              </div>
              <div className="form-group col-4 col-md-3">
                <InputDate
                  label={t("form.birthday")}
                  placeholder="dd/mm/yyyy"
                  className="form-control input-form"
                  errors={errors}
                  name="birthday"
                  mask="99/99/9999"
                  ref={birthdayRef}
                  id="input-date"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-6">
                <InputText
                  className="form-control input-form"
                  name="phone"
                  label={t("form.phone")}
                  errors={errors}
                  type="text"
                  placeholder={t("form.phone")}
                  ref={phoneRef}
                />
              </div>
              <div className="form-group col-6">
                <InputText
                  className="form-control input-form"
                  name="email"
                  label={t("form.email")}
                  errors={errors}
                  type="text"
                  placeholder={t("form.email")}
                  ref={emailRef}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-6">
                <Select
                  label={t("form.province")}
                  className="form-control"
                  name="province_id"
                  errors={errors}
                  key_value="province_id"
                  key_label="province_name"
                  data={province}
                  include_blank={t("form.choose")}
                  ref={provinceIdRef}
                  onChange={onChangeProvince}
                />
              </div>
              <div className="form-group col-6">
                <Select
                  label={t("form.district")}
                  className="form-control"
                  name="district_id"
                  errors={errors}
                  key_value="district_id"
                  key_label="district_name"
                  data={district}
                  include_blank={t("form.choose")}
                  onChange={onChangeDistrict}
                  ref={districtIdRef}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-6">
                <Select
                  label={t("form.ward")}
                  className="form-control"
                  name="ward_id"
                  errors={errors}
                  key_value="ward_id"
                  key_label="ward_name"
                  data={ward}
                  include_blank={t("form.choose")}
                  ref={wardIdRef}
                />
              </div>
              <div className="form-group col-6">
                <InputText
                  className="form-control input-form"
                  name="address"
                  label={t("form.address")}
                  errors={errors}
                  type="text"
                  placeholder={t("form.addressPlaceholder")}
                  ref={addressRef}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-6">
                <Select
                  label={t("form.branch")}
                  className="form-control"
                  name="branch_id"
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  data={branch}
                  include_blank={t("form.choose")}
                  onChange={onChangeBranch}
                  ref={branchIdRef}
                />
              </div>
              <div className="form-group col-6">
                <Select
                  label={t("form.club")}
                  className="form-control"
                  name="club_id"
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  data={club}
                  include_blank={t("form.choose")}
                  ref={clubIdRef}
                />
              </div>
            </div>

            <div className="text-center mb-3 mt-3">
              <button type="submit" className="btn-form">
                {t("form.btnSendInfo")}
              </button>
            </div>
          </form>
          <p className="form-register__description">
            {t("form.descriptionBottom")}
          </p>
          <div
            className="block-open-form block-open-form__desktop"
            onClick={openForm}
          >
            <img
              className="block-open-form__icon-left"
              src={iconOpenLeft}
              alt=""
            />
          </div>
          <div
            className="block-open-form block-open-form__mobile"
            onClick={openFormMobile}
          >
            <img
              className="block-open-form__icon-down"
              src={iconOpenUp}
              alt=""
            />
          </div>
          <div className="block-ask-account block-ask-account__desktop" onClick={openForm}>
            <span>{t("form.haveAccount")}</span>
          </div>
          <div className="block-ask-account block-ask-account__mobile" onClick={openFormMobile}>
            <span>{t("form.haveAccount")}</span>
          </div>
        </div>
        <div className="form-login-respon" id="form-login-respon">
          <div className="form-login" id="form-login">
            <h3 className="form-login__title">{t("form.login")}</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group form-group__custom">
                <InputText
                  className="form-control form-control input-form"
                  name="phone"
                  label={t("form.loginEmail")}
                  errors={loginErrors}
                  type="text"
                  placeholder={t("form.loginEmail")}
                  ref={phoneLoginRef}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && (
                  <span className="invalid-text">{formik.errors.phone}</span>
                )}
              </div>
              <div className="form-group form-group__custom form-group__custom--position">
                <img onClick={showPassword} className="icon-eye" src={iconEye} alt="" />
                <InputText
                  id="password"
                  className="form-control form-control input-form"
                  name="password"
                  label={t("form.loginPassword")}
                  errors={loginErrors}
                  type="password"
                  ref={passwordLoginRed}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.errors.password && (
                  <span className="invalid-text">{formik.errors.password}</span>
                )}
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn-form btn-form__login">
                  {t("form.btnLogin")}
                </button>
              </div>
            </form>
            <div
              className="block-close-form block-close-form__desktop"
              onClick={closeForm}
            >
              <img src={iconOpenRight} alt="" />
            </div>
            <div
              className="block-close-form block-close-form__mobile"
              onClick={closeFormMobile}
            >
              <img src={iconOpenDown} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
