import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

import "../../assets/styles/detail-search/detail-search.scss";
import DetailItem from "./detailItem";
import PaginationCustom from "../../components/pagination";
import { useLocation, useHistory } from "react-router-dom";
import BannerCommon from "../../components/BannerCommon";
import BreadcrumbComponent from "./../../components/BreadcrumbCommon/index";

import { postData } from "../../utils/service";
import {
  URL_API_BANNERS,
  URL_SEARCH_HISTORY,
  URL_SEARCH_INFORMATION,
  URL_SEARCH_OTHER,
} from "../../utils/constants";

const { TabPane } = Tabs;

const tabList = [
  {
    key: "news",
    tab: "Thông tin",
  },
  {
    key: "history",
    tab: "Lịch sử",
  },
  {
    key: "skill",
    tab: "Kỹ thuật",
  },
  {
    key: "activity",
    tab: "Hoạt động",
  },
];

const DetailSearch = () => {
  const [tabActive, setTabActive] = useState("news");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [imageBanner, setImageBanner] = useState("");

  const history = useHistory();
  const { t } = useTranslation("translation");
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const searchParams = useQuery();
  const searchValue = decodeURIComponent(searchParams.get("search"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchData = useCallback(() => {
    let endpoint;
    let params = {
      per_page: 6,
      page: page,
    };

    if (searchValue) {
      params.keyword = searchValue;
    }

    switch (tabActive) {
      case "history":
        endpoint = URL_SEARCH_HISTORY;
        params.type = 2;
        break;
      case "skill":
        endpoint = URL_SEARCH_OTHER;
        params.type_menu = 1;
        break;
      case "activity":
        endpoint = URL_SEARCH_OTHER;
        params.type_menu = 2;
        break;
      case "news":
        endpoint = URL_SEARCH_INFORMATION;
        break;
      default:
        return;
    }

    postData(endpoint, params)
      .then((res) => {
        setData(res?.data?.data || []);
        setTotal(res?.data?.paginate?.total || 0);
      })
      .catch((err) => {
        setData([]);
        setTotal(0);
      });
  }, [page, searchValue, tabActive]);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "SEARCH"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, searchValue, tabActive]);

  const handleChangeTabs = (key) => {
    setTabActive(key);
  };

  const handleViewDetail = (id) => {
    history.push(`/${tabActive}/${id}`);
  };

  const renderTab = () => {
    return tabList.map((item) => {
      return (
        <TabPane
          tab={<span className="tab-title-text">{item.tab}</span>}
          key={item.key}
        >
          <p className="search__detail__tab__count">{total || 0} Kết quả</p>
          <div className="search__detail__tab__content">
            {data?.map((it) => {
              return (
                <DetailItem
                  data={it}
                  key={it?.id}
                  handleViewDetail={handleViewDetail}
                />
              );
            })}
          </div>

          {data?.length > 0 && (
            <PaginationCustom
              current={page}
              pageSize={6}
              total={total}
              showSizeChanger={false}
              onChange={(page) => setPage(page)}
            />
          )}
        </TabPane>
      );
    });
  };

  return (
    <div>
      <BannerCommon img={imageBanner} text1={t("search.search")} />
      <div className="container">
        <div className="container__breadcrumb">
          <BreadcrumbComponent
            dataBreadcrumb={[
              { title: t("search.home"), url: "/" },
              { title: t("search.search") },
            ]}
          />
        </div>
        <div className="search__detail">
          <p className="search__detail__result">{t("search.result")}</p>
          <div className="search__detail__tab">
            <Tabs defaultActiveKey={tabActive} onChange={handleChangeTabs}>
              {renderTab()}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSearch;
