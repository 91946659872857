import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { PhoneTwoTone, MailFilled } from "@ant-design/icons";

import MarkerClubIcon from "../../assets/images/icons/marker_club.svg";
import { TEXT_ERROR_API, URL_API_DETAIL_BRANCH } from "../../utils/constants";
import { getTakenData } from "../../utils/service";
import LoadingContainer from "../../components/LoadingContainer";

const ContactUs = ({ google }) => {
  const [infoContact, setInfoContact] = useState({
    id: 3,
    name: "",
    intro_branch: "",
    branch_leader: "",
    bgr_image: "",
    address: "",
    lat: 16.4645325,
    lng: 107.5884788,
    area_id: null,
    leader_email: "",
    leader_address: "",
    leader_phone: "",
  });
  const [selectedMarker, setSelectedMarker] = useState({});
  const [loading, setLoading] = useState(true);

  const handleMarkerMaps = (id, lat, lng, marker) => {
    setSelectedMarker({
      id,
      lat,
      lng,
      marker,
    });
  };

  const fetchDataContact = () => {
    getTakenData(`${URL_API_DETAIL_BRANCH}/3`)
      .then((res) => {
        setLoading(false);
        setInfoContact(
          res.data.id
            ? res.data
            : {
                id: 3,
                name: "",
                intro_branch: "",
                branch_leader: "",
                bgr_image: "",
                address: "",
                lat: 16.4645325,
                lng: 107.5884788,
                area_id: null,
                leader_email: "",
                leader_address: "",
                leader_phone: "",
              }
        );
      })
      .catch(() => {
        alert(TEXT_ERROR_API);
      });
  };

  useEffect(() => {
    fetchDataContact();
  }, []);
  return (
    <section className="contact-block">
      <LoadingContainer hidden={!loading} />
      {!loading && (
        <div
          className="position-relative w-100"
          style={{ minHeight: "calc(100vh - 75px)" }}
        >
          <Map
            google={google}
            initialCenter={{
              lat: parseFloat(infoContact.lat),
              lng: parseFloat(infoContact.lng),
            }}
            center={{
              lat: parseFloat(infoContact.lat),
              lng: parseFloat(infoContact.lng),
            }}
            fullscreenControl
            zoom={12}
            styles={[
              {
                featureType: "water",
                elementType: "labels.text",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
            ]}
            gestureHandling="cooperative"
            panControl
            rotateControl
          >
            <Marker
              position={{ lat: infoContact.lat, lng: infoContact.lng }}
              name={infoContact.name}
              onClick={(props, marker) =>
                handleMarkerMaps(
                  infoContact.lng.id,
                  infoContact.lat,
                  infoContact.lng,
                  marker
                )
              }
              icon={{
                url: MarkerClubIcon,
                anchor: new window.google.maps.Point(10, 10),
                scaledSize: new window.google.maps.Size(20, 30),
              }}
            ></Marker>
            <InfoWindow
              position={{ lat: infoContact.lat, lng: infoContact.lng }}
              marker={selectedMarker.marker && selectedMarker.marker}
              visible={true}
            >
              <div className="pl-1 pt-2 text-center contact-block-content">
                <div>
                  <h5 style={{ color: "#353796" }}>Nghĩa Dũng Karate-Do</h5>
                  {infoContact["leader_address"] ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="m-0 mr-1" style={{ width: 10 }}>
                        <img width="100%" src={MarkerClubIcon} alt="" />
                      </p>
                      <p className="m-0" style={{ color: "#353796" }}>
                        {infoContact["leader_address"]}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {infoContact["leader_phone"] ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="mb-1 mr-1" style={{ width: 10 }}>
                        <PhoneTwoTone />
                      </p>
                      <p className="m-0" style={{ color: "#353796" }}>
                        {infoContact["leader_phone"]}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {infoContact["leader_email"] ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="m-1 mr-1" style={{ width: 10 }}>
                        <MailFilled />
                      </p>
                      <p className="m-0" style={{ color: "#353796" }}>
                        {infoContact["leader_email"]}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="text-center">
                    {infoContact["bgr_image"] && (
                      <img
                        width="180px"
                        className="rounded mt-2"
                        src={
                          process.env.REACT_APP_ENDPOINT +
                          infoContact["bgr_image"]
                        }
                        alt=""
                      />
                    )}
                  </div>

                  {infoContact["intro_branch"] ? (
                    <p className="m-0 mt-2"> {infoContact["intro_branch"]}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </InfoWindow>
          </Map>
        </div>
      )}
      {/* <div className="mt-3 mb-3">
        {infoContact["leader_address"] ? (
          <p className="m-0" style={{ color: "#353796" }}>
            Địa chỉ: {infoContact["leader_address"]}
          </p>
        ) : (
          ""
        )}
        {infoContact["leader_phone"] ? (
          <p className="m-0" style={{ color: "#353796" }}>
            Điện thoại: {infoContact["leader_phone"]}
          </p>
        ) : (
          ""
        )}
        {infoContact["leader_email"] ? (
          <p className="m-0" style={{ color: "#353796" }}>
            E-mail: {infoContact["leader_email"]}
          </p>
        ) : (
          ""
        )}
      </div> */}
    </section>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAiQX1yfcvHCeM98e6asi-Wi-Y_H4-V1Qw",
  version: "3.42",
})(ContactUs);
