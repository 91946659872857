import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../assets/styles/breadcrumb/breadcrumb.scss";
import "../../assets/styles/history/history.scss";
import { URL_API_NEWS } from "../../utils/constants";
import { postData } from "../../utils/service";

const NewsPage = () => {
  const { t } = useTranslation();

  const [dataNews, setDataNews] = useState();

  const fetchData = () => {
    postData(URL_API_NEWS, { per_page: 20 }).then((res) => {
      setDataNews(res.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <LoadingContainer hidden={!dataHistory.loading} /> */}
      <div className="history_v2 padding-header">
        <div className="container-1200">
          <div className="history_v2-breadcrumb">
            <ul className="px-0 py-3">
              <li>
                <Link to="/">{t("common.home")} / &nbsp;</Link>
              </li>
              <li>{t("header.info")}</li>
            </ul>
          </div>
          {/* <div className="history_v2-content">
            <h3 className="history_v2-content_title">{dataHistory.title}</h3>
            <div className="">
              <div
                dangerouslySetInnerHTML={{ __html: dataHistory.content }}
                className="ck-content"
              />
              <img
                src={
                  process.env.REACT_APP_ENDPOINT + dataHistory["attach_files"]
                }
                alt={dataHistory.title}
              />
            </div>
          </div>
          <div className="history_v2-redirect d-flex justify-content-between py-5">
            <div onClick={backHistory} className="d-flex align-items-center">
              <img
                src={IconRedirect}
                alt=""
                width="10px"
                style={{ transform: "rotate(180deg)" }}
              />
              <p className="m-0 pl-3">{dataHistoryPrev.title}</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="m-0 pr-3">{dataHistoryNext.title}</p>
              <img src={IconRedirect} alt="" width="10px" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default NewsPage;
