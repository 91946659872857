import React from "react";
import { Link } from "react-router-dom";

import ClockIcon from "../../assets/images/icons/clock.svg";
import MarketIcon from "../../assets/images/icons/market_events.svg";
import DefaulImage from "../../assets/images/default_event_image.png";

import "../../assets/styles/events/events.scss";
const EventItem = ({ event }) => {
  const { id, title, time_end, time_start, image, address } = event;

  return (
    <Link to={`/events/${id}`} className={`event_item fade-in`}>
      <div
        className="event_item-image"
        style={{
          background: `url('${
            image ? process.env.REACT_APP_ENDPOINT + image : DefaulImage
          }') center center no-repeat`,
        }}
      ></div>
      <div className="event_item-text">
        <p className="title_block text-center">{title}</p>
        <div>
          {address ? (
            <div className="event_item-info  text-center">
              <img src={MarketIcon} alt="" /> {address}
            </div>
          ) : null}

          <div className="event_item-info text-center">
            <img src={ClockIcon} alt="" />

            {time_start ? time_start : time_end}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventItem;
