export const MAPS_ACTION_TYPE = {
  SET_DATA: "SET_DATA_MAPS",
};
const defaultState = {
  dataMaps: [],
  dataBranchList: [],
};
const reducers = (state = defaultState, actions) => {
  switch (actions.type) {
    case MAPS_ACTION_TYPE.SET_DATA: {
      return actions.payload;
    }

    default:
      return state;
  }
};
export default reducers;
