import React, { useEffect, useState } from "react";
import { Modal, Table, Avatar } from "antd";
import { Spinner } from "react-bootstrap";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHornors } from "../../hooks/hornor";
import moment from "moment";
import Item from "antd/lib/list/Item";

const ModalHonor = ({ record, closeModal }) => {
  const { id, fullName, avatar, user_id, infoBelt, infoClub, disable } = record;
  const { t } = useTranslation();
  const [hornors, hororLoading, fetchHornors] = useHornors();

  const baseColumn = [
    {
      title: t("search.numericalOrder"),
      render: (text, record, index) => index + 1,
      responsive: ["md"],
    },
    {
      title: t("search.dateReward"),
      dataIndex: "license_date",
      responsive: ["md"],
      render: (data) => {
        return moment(data).format("DD/MM/YYYY");
      },
    },
    {
      title: t("search.nameAchieve"),
      dataIndex: "title",
    },
    {
      title: t("search.medal"),
      dataIndex: "certificate_image",
      render: (image, record) => {
        if (typeof image === "string") {
          return (
            <div className="reward_list">
              <a
                data-fancybox={`gallery-${record.id}`}
                href={process.env.REACT_APP_ENDPOINT + image}
              >
                <img
                  src={process.env.REACT_APP_ENDPOINT + image}
                  className="image_reward"
                  alt=""
                />
              </a>
              <span>{record?.infoUser?.name}</span>
            </div>
          );
        }
        if (typeof image === "object") {
          return (
            <div className="reward_list">
              {image.map((img, index, record) => (
                <span>
                  <a
                    data-fancybox={`gallery-${record.id}`}
                    key={index}
                    href={process.env.REACT_APP_ENDPOINT + img}
                  >
                    <img
                      src={process.env.REACT_APP_ENDPOINT + img}
                      className="image_reward"
                      alt=""
                    />
                  </a>
                  <span>{record?.infoUser?.name}</span>
                </span>
              ))}
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    fetchHornors(id, { per_page: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      onCancel={closeModal}
      width={1140}
      className="modal_hornor"
      footer={null}
      visible={true}
      keyboard
      getContainer={false}
      centered
    >
      {hororLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="modal_hornor_content">
          <p
            className="modal_hornor_content-title"
            style={{ textAlign: "center" }}
          >
            Thông tin thành tích võ sinh
          </p>
          {/* <div className="modal_hornor_content--header"> */}
          {/* {avatar ? (
              <Avatar src={process.env.REACT_APP_ENDPOINT + avatar}></Avatar>
            ) : (
              <Avatar icon={<UserOutlined />} />
            )} */}
          {/* <div className="modal_hornor_content--header_info">
              <p className="user_name">{fullName}</p>
              <div className="user_info">
                <span>Mã số: {user_id}</span>
                <span>Cấp bậc: {infoBelt.name}</span>
              </div>
              <p
                className={
                  disable
                    ? "disable user_place_trainning"
                    : "training user_place_trainning"
                }
              >
                Đang sinh hoạt tại {infoClub ? infoClub.name : ""}
              </p>
            </div> */}
          {/* </div> */}
          {/* <div className="line_break"></div> */}
          <div className="table_horder_wrapper">
            <Table
              columns={baseColumn}
              locale={{ emptyText: t("common.nullData") }}
              pagination={false}
              rowKey={(record) => record.id}
              dataSource={hornors}
            ></Table>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalHonor;
