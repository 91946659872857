import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import LoadingContainer from '../../components/LoadingContainer';
import BannerImage from '../../components/BannerImage';
import image_banner from '../../assets/images/background-thu-vien-nghia dung.png';
import '../../assets/styles/library/library.scss';
import IconBook from '../../assets/images/icons/icon_book.svg';
import IconNote from '../../assets/images/icons/icon_note.svg';
import IconVideo from '../../assets/images/icons/icon_video.svg';
import IconGallery from '../../assets/images/icons/icon_gallery.svg';
import BookContent from './BookContent';
import Posts from './Posts';
import Videocontent from './VideoContent';
import GalleryContent from './GalleryContent';
import Page404 from '../Page404';
import { getTakenData } from '../../utils/service';
import {
  TEXT_ERROR_API,
  URL_API_INFO_FORMS_BY_TYPE
} from '../../utils/constants';

export const URL_PAGE_LIBRARY = {
  images: 'images',
  ebook: 'ebook',
  videos: 'videos',
  documents: 'documents'
};

const LibraryType = ({ type }) => {
  switch (type) {
    case URL_PAGE_LIBRARY.ebook:
      return <BookContent tabId={0} />;
    case URL_PAGE_LIBRARY.images:
      return <GalleryContent tabId={3} />;
    case URL_PAGE_LIBRARY.videos:
      return <Videocontent tabId={2} />;
    case URL_PAGE_LIBRARY.documents:
      return <Posts tabId={1} />;
    default:
      return <Page404 />;
  }
};

const Library = () => {
  const [infoPage, setInfoPage] = useState({
    id: 43,
    title: '',
    type: 6,
    content: '',
    attach_files: []
  });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { t } = useTranslation();
  const libraryList = [
    {
      id: 1,
      name: t('common.book'),
      icon: IconBook,
      url: URL_PAGE_LIBRARY.ebook
    },

    {
      id: 2,
      name: t('common.posts'),
      icon: IconNote,
      url: URL_PAGE_LIBRARY.documents
    },

    {
      id: 3,
      name: 'Video',
      icon: IconVideo,
      url: URL_PAGE_LIBRARY.videos
    },
    {
      id: 4,
      name: t('common.photoLibrary'),
      icon: IconGallery,
      url: URL_PAGE_LIBRARY.images
    }
  ];

  const fetchInfoPage = () => {
    getTakenData(`${URL_API_INFO_FORMS_BY_TYPE}/6`)
      .then((res) => {
        setInfoPage(
          res.data.length
            ? res.data[0]
            : {
                id: 43,
                title: '',
                type: 6,
                content: '',
                attach_files: []
              }
        );
        setLoading(false);
      })
      .catch(() => alert(TEXT_ERROR_API));
  };

  useEffect(() => {
    let timer = setTimeout(function () {
      try {
        window.scroll({
          top: 200,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        window.scrollTo(0, 200);
      }
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [id]);

  useEffect(() => {
    fetchInfoPage();
  }, []);

  return (
    <>
      <LoadingContainer hidden={!loading} />
      {!loading && (
        <section className="library_content">
          <BannerImage image={image_banner}>
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb p-0 pt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">{t('common.home')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('header.library')}
                  </li>
                </ol>
              </nav>
              <div className="row">
                <div className="d-none d-sm-block col-12 col-md-4">
                  <div
                    className="library_content-image-box"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_ENDPOINT +
                        infoPage.attach_files[0]
                      })`
                    }}
                  />
                </div>
                <div className="section_banner col-12 col-md-8">
                  <h4 className="font-weight-bold text-center text-uppercase mb-2 title-block text-sm-left">
                    {t('header.library')}
                  </h4>
                  <div className="d-md-none border-title-header border-bottom w-100 mb-3 mb-md-5" />
                  <div
                    className="ck-editor text-justify text-md-left"
                    dangerouslySetInnerHTML={{
                      __html: infoPage.content
                        ? infoPage.content
                        : t('textBanner.library')
                    }}
                  />

                  <ul className="list_content_library">
                    {libraryList &&
                      libraryList.map((item, index) => (
                        <li key={index}>
                          <Link
                            className={id === item.url ? 'active' : ''}
                            to={`/library/${item.url}`}
                          >
                            <img src={item.icon} alt="" /> {item.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <ul className="list_content_library list_content_library_mobie">
                {libraryList &&
                  libraryList.map((item, index) => {
                    const classTab = id === item.url ? 'active' : '';
                    return (
                      <li key={index}>
                        <Link className={classTab} to={`/library/${item.url}`}>
                          <img src={item.icon} alt="" /> {item.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </BannerImage>
          <Container className="mt-md-5">
            <LibraryType type={id} />
          </Container>
        </section>
      )}
    </>
  );
};

export default Library;
