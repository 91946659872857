import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/LoadingContainer";
import LazyLoad from "react-lazyload";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { newsList } from "../../utils/dataMock";
import TitleLine from "../../components/TitleLine";
import { getTakenData } from "../../utils/service";
import { URL_API_POSTS } from "../../utils/constants";
import { URL_PAGE_LIBRARY } from "../Library/";
import "../../assets/styles/detail/detail.scss";
import TitleUnderLine from "../../components/TitleUnderLine";
import RelativedPosts from "./RelativedPosts";
import ImageGallery from "../../components/ImageGallery";
import ModalGallery from "../../components/ModalGallery";

const breakpointColumnsObj = {
  default: 3,
  768: 2,
  576: 1,
};

const DetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [modalGallery, setModalGallery] = useState({
    visible: false,
    data: [],
  });
  const [detail, setDetail] = useState({
    data: "",
    loading: true,
    related: newsList,
  });
  const getDataForDetail = () => {
    if (id) {
      getTakenData(URL_API_POSTS(id)).then((res) => {
        setDetail({
          loading: false,
          data: res.data,
        });
      });
    }
  };

  // open Gallery
  const handleGallery = () => {
    setModalGallery({
      visible: true,
      data: detail.data.attach_files,
    });
  };
  // close modal gallery
  const closeModalGallery = () => {
    setModalGallery({
      visible: false,
      data: [],
    });
  };
  useEffect(() => {
    if (!detail.loading) {
      setDetail({
        ...detail,
        loading: true,
      });
    }
    getDataForDetail();
    let timer = setTimeout(function () {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }, 200);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isHaveImages =
    detail.data.attach_files && detail.data.attach_files.length !== 0;
  return (
    <>
      <Loading hidden={!detail.loading} />
      {!detail.loading && (
        <div className="detail_page pt-0">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb p-0 pt-3">
                <li className="breadcrumb-item">
                  <Link to="/">{t("common.home")}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to={`/library/${URL_PAGE_LIBRARY.documents}`}>
                    {t("common.posts")}
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
          {modalGallery.visible ? (
            <ModalGallery
              visible={modalGallery.visible}
              gallery={modalGallery.data}
              closeModal={closeModalGallery}
            />
          ) : null}
          <div className="container fade-in">
            <TitleUnderLine
              title={detail.data.title}
              author={detail.data.user.name}
              time={detail.data.updated_at}
            ></TitleUnderLine>
            <p className="detail_page--desc text-center">
              {detail.data.description}
            </p>

            <div
              className="detail_page--content_post ck-content"
              dangerouslySetInnerHTML={{ __html: detail.data.content }}
            ></div>
            {isHaveImages && (
              <div className="detail_page--image_detail">
                <TitleLine title={{ name: t("common.imageDetailPost") }} />
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="gallery_wrapper"
                  columnClassName="gallery_wrapper-grid_column"
                >
                  {detail.data.attach_files.map((item, index) => (
                    <ImageGallery
                      handleGallery={handleGallery}
                      id={item.id}
                      key={index.toString()}
                      image={item}
                      haveDescription={false}
                      gallery={detail.data.attach_files}
                    />
                  ))}
                </Masonry>
              </div>
            )}
            <LazyLoad>
              <RelativedPosts id={id} />
            </LazyLoad>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailPage;
