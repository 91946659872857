import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../assets/styles/breadcrumb/breadcrumb.scss";
import "../../assets/styles/history/history.scss";
import { URL_API_HISTORY } from "../../utils/constants";
import { getTakenData } from "../../utils/service";

const INDEX_REFS = {
  "tu-okinawa-den-tokyo": 0,
  "tu-nhat-ban-den-viet-nam": 1,
  "tu-dao-duong-suzuki-dojo-noel-den-he-phai-suzucho-karate-do": 2,
  "tu-suzucho-karate-do-ryu-den-nghia-dung-karate-do": 3,
};

const DetailsMap = () => {
  const history = useHistory();

  const [dataHistory, setDataHistory] = useState([]);
  const [dataHistoryNext, setDataHistoryNext] = useState([]);
  const [dataHistoryPrev, setDataHistoryPrev] = useState([]);
  const { id } = useParams();
  const { t } = useTranslation();

  const fetchDataHistory = () => {
    const idNext = INDEX_REFS[id] < 4 ? INDEX_REFS[id] + 1 : 4;
    const idPrev = INDEX_REFS[id] > 0 ? INDEX_REFS[id] - 1 : 0;
    try {
      getTakenData(URL_API_HISTORY).then((res) => {
        setDataHistory(res.data[INDEX_REFS[id]]);
        setDataHistoryNext(res.data[idNext]);
        setDataHistoryPrev(res.data[idPrev]);
      });
    } catch (error) {
      setDataHistory([]);
    }
  };

  const backHistory = () => {
    let ids = INDEX_REFS[id];
    if (ids === 1) {
      history.push("/history/tu-okinawa-den-tokyo");
    }
  };

  useEffect(() => {
    fetchDataHistory();
  }, []);

  return (
    <>
      {/* <LoadingContainer hidden={!dataHistory.loading} /> */}
      <div className="history_v2 padding-header">
        <div className="container-1200">
          <div className="history_v2-breadcrumb">
            <ul className="px-0 py-3">
              <li>
                <Link to="/">{t("common.home")} / &nbsp;</Link>
              </li>
              <li>{t("common.distribution")}</li>
            </ul>
          </div>
          {/* <div className="history_v2-content">
            <h3 className="history_v2-content_title">{dataHistory.title}</h3>
            <div className="">
              <div
                dangerouslySetInnerHTML={{ __html: dataHistory.content }}
                className="ck-content"
              />
              <img
                src={
                  process.env.REACT_APP_ENDPOINT + dataHistory["attach_files"]
                }
                alt={dataHistory.title}
              />
            </div>
          </div>
          <div className="history_v2-redirect d-flex justify-content-between py-5">
            <div onClick={backHistory} className="d-flex align-items-center">
              <img
                src={IconRedirect}
                alt=""
                width="10px"
                style={{ transform: "rotate(180deg)" }}
              />
              <p className="m-0 pl-3">{dataHistoryPrev.title}</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="m-0 pr-3">{dataHistoryNext.title}</p>
              <img src={IconRedirect} alt="" width="10px" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DetailsMap;
