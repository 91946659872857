import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/category-document/category-document.scss";
import iconList from "./../../assets/images/home-update/icon-list.svg";
import vectorNext from "../../assets/images/home-update/vector-next.svg";
import vectorPreview from "../../assets/images/home-update/vector-preview.svg";
import { useParams } from "react-router-dom";
import { URL_API_POSTS_LIST_TECHNICAL_ACTIVE } from "../../utils/constants";
import { postData } from "../../utils/service";
import { useTranslation } from "react-i18next";
import download from "../../assets/images/icons/download.svg";
import { Translator, Translate } from "react-auto-translate";
const CategorySkill = () => {
  const { id: menu_id } = useParams();
  const [dataCategorySkill, setDataCategorySkill] = useState({});
  const [dataRelativeSkill, setDataRelativeSkill] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getInforCategorySkill();
  }, [menu_id]);

  const getInforCategorySkill = () => {
    postData(URL_API_POSTS_LIST_TECHNICAL_ACTIVE, {
      parent_id: 1,
      menu_id: menu_id,
    }).then((res) => {
      setDataCategorySkill(res?.data?.data);
      setDataRelativeSkill(res?.data?.relatives);
    });
  };

  const convertDataHtml = (data) => {
    const re = /<.*?[>]*>/gi;
    const result = data.match(re);
    const converted_result = [];
    if (result?.length > 0) {
      let _tmps = [];
      let _tmps_only_text = [];
      for (let i = 0; i < result.length - 1; i++) {
        let start = data.indexOf(result[i]);
        let _start = start + result[i].length;
        let end = data.indexOf(result[i + 1]);
        let _end = data.indexOf(result[i + 1]);
        if (start === end) {
          _end = data.indexOf(result[i + 1], 1);
          end = data.indexOf(result[i + 1], 1) + result[i + 1].length;
        } else {
          end = end + result[i + 1].length;
        }
        let tmp = data.substring(start, end);
        _tmps.push(tmp);
        let only_text = data.substring(_start, _end);
        if (only_text !== "" && only_text !== "&nbsp;") {
          _tmps_only_text.push(only_text);
        } else {
          _tmps_only_text.push(only_text.replace("&nbsp;", " "));
        }
        data = data.substring(end - result[i + 1].length);
      }

      for (let i = 0; i < result.length - 1; i++) {
        if (i > 0) {
          converted_result.push({
            beforeTag: "",
            content: _tmps_only_text[i],
            afterTag: result[i + 1],
          });
        } else {
          converted_result.push({
            beforeTag: result[i],
            content: _tmps_only_text[i],
            afterTag: result[i + 1],
          });
        }
      }
    }
    return converted_result;
  };

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <div className='category-document'>
      <div className='container-1200'>
        <div className='banner-category-document'>
          <ul className='banner-category-document__breadcrumb'>
            <li>
              <span>
                <Link to='/'>
                  {/* <Translate> */}
                  {t("common.home")}
                  {/* </Translate> */}
                </Link>
              </span>
            </li>
            <li>
              <span>
                {/* <Translate> */}
                {t("common.tech")}
                {/* </Translate> */}
              </span>
            </li>
          </ul>
          <p className='title-category-document'>
            {/* <Translate> */}
            {dataCategorySkill?.title || ""}
            {/* </Translate> */}
          </p>
        </div>
        <div className='content-category-document'>
          <div
            className='ck-content'
            dangerouslySetInnerHTML={{ __html: dataCategorySkill?.content }}
          ></div>
          {/* {dataCategorySkill?.content &&
              convertDataHtml(dataCategorySkill?.content)?.map((itemHtml) => {
                const content = <Translate>{itemHtml.content}</Translate>;
                return (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: itemHtml.beforeTag,
                      }}
                    ></div>
                    {content}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: itemHtml.afterTag,
                      }}
                    ></div>
                  </div>
                );
              })} */}

          {dataCategorySkill?.attach_files?.length > 0 && (
            <div
              className='d-flex align-items-center justify-content-between mt-4'
              style={{ background: "#F7F7FC", padding: "15px" }}
            >
              <p className='mr-3 content-custom'>
                {/* <Translate> */}
                {dataCategorySkill?.title}
                {/* </Translate> */}
              </p>
              <div>
                <button
                  className='d-flex'
                  style={{
                    background: "#FC3A52",
                    color: "#fff",
                    border: "none",
                    padding: "12px 12px 12px 12px ",
                    outline: "none",
                  }}
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_ENDPOINT_API}download?file=${dataCategorySkill?.attach_files[0]}&name=${dataCategorySkill?.title}`
                    );
                  }}
                >
                  <img src={download} className='pr-2' />
                  <span className='content-custom'>
                    {/* <Translate> */}
                    Tải xuống
                    {/* </Translate> */}
                  </span>
                </button>
              </div>
            </div>
          )}

          {dataRelativeSkill.length > 0 && (
            <div className='block-list-document'>
              <p className='title'>
                {/* <Translate> */}
                {t("common.relatedArticles")}
                {/* </Translate> */}
              </p>
              <hr className='line' />
              <ul>
                {dataRelativeSkill?.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className='block-list-document__item'>
                        <span className='content-custom'>
                          <img className='icon-list' src={iconList} />
                          {/* <Translate> */}
                          {item?.title}
                          {/* </Translate> */}
                        </span>
                        <Link
                          className='see-detail content-custom'
                          to={`/skill/${menu_id}/detail/${item?.id}`}
                        >
                          {/* <Translate> */}
                          {t("common.viewDetail")}
                          {/* </Translate> */}
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className='block-next-preview-document'>
          <div className='block-next-preview'>
            <div className='block-next-preview__left'>
              {dataCategorySkill?.prev && (
                <Link to={`/skill/${dataCategorySkill?.prev?.id}`}>
                  <img src={vectorPreview} alt='' />
                  {/* <Translate> */}
                  {dataCategorySkill?.prev?.name}
                  {/* </Translate> */}
                </Link>
              )}
            </div>
            <div className='block-next-preview__right'>
              {dataCategorySkill?.next && (
                <Link to={`/skill/${dataCategorySkill?.next?.id}`}>
                  {/* <Translate> */}
                  {dataCategorySkill?.next?.name}
                  {/* </Translate> */}
                  <img src={vectorNext} alt='' />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Translator>
  );
};

export default CategorySkill;
