import { useState, useEffect, useCallback } from "react";
import { postData } from "../utils/service";
import { URL_API_HORNOR } from "../utils/constants";

export const useHornors = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = useCallback((id, params) => {
    setLoading(true);

    postData(URL_API_HORNOR(id), params).then((res) => {
      const result = res.data.data;
      setData(result);
      setLoading(false);
    });
  }, []);

  return [data, loading, fetchData];
}
