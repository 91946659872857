import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Translate } from "react-auto-translate";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import {
  URL_API_LIBRARY_HOME,
  URL_API_TYPE_DOCUMENT,
  GET_LIST_CATEGORY,
} from "../../utils/constants";
import { setHiddenHeader } from "../../actions";
import { changeLanguage } from "../../utils/functions";
import { postData, getTakenData } from "../../utils/service";
import ModalGallery from "../../components/ModalGallery";
import PaginationCustom from "../../components/pagination";

import iconFolder from "./../../assets/images/home-update/icon-folder.svg";
import iconPlay from "../../assets/images/icons/play_button.svg";

const { TabPane } = Tabs;

const SectionLibrary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataBook, setDataBook] = useState([]);
  const [paginateBook, setPaginateBook] = useState({});

  const [dataImage, setDataImage] = useState([]);
  const [paginateImage, setPaginateImage] = useState({});

  const [dataVideo, setDataVideo] = useState([]);
  const [paginateVideo, setPaginateVideo] = useState({});

  const [dataTypeDocument, setDataTypeDocument] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [filterCategory, setFilterCategory] = useState(0);

  const [modalGallery, setModalGallery] = useState({
    visible: false,
    data: [],
  });

  useEffect(() => {
    getTakenData(GET_LIST_CATEGORY).then((res) => {
      setCategoryOption([
        {
          value: 0,
          label: "Tất cả",
        },
        ...res?.data?.map((item) => ({
          value: item?.id,
          label: item?.title,
        })),
      ]);
    });
  }, []);

  const fetchDocument = useCallback(() => {
    const payload = {
      category_id: filterCategory,
    };
    try {
      getTakenData(URL_API_TYPE_DOCUMENT, payload).then((res) => {
        setDataTypeDocument(res?.data);
      });
    } catch (error) {
      setDataTypeDocument([]);
    }
  }, [filterCategory]);

  const fetchBook = useCallback(
    (pageBook = 1) => {
      try {
        postData(URL_API_LIBRARY_HOME, {
          per_page: 8,
          catalog_id: 3,
          page: pageBook,
          category_id: filterCategory,
        }).then((res) => {
          setDataBook(res?.data?.data);
          setPaginateBook(res?.data?.paginate);
        });
      } catch (error) {
        setDataBook([]);
      }
    },
    [filterCategory]
  );

  const fetchImage = useCallback(
    (pageImage = 1) => {
      try {
        postData(URL_API_LIBRARY_HOME, {
          per_page: 8,
          catalog_id: 4,
          page: pageImage,
          category_id: filterCategory,
        }).then((res) => {
          setDataImage(res?.data?.data);
          setPaginateImage(res?.data?.paginate);
        });
      } catch (error) {
        setDataImage([]);
      }
    },
    [filterCategory]
  );

  const fetchVideo = useCallback(
    (pageVideo = 1) => {
      try {
        postData(URL_API_LIBRARY_HOME, {
          per_page: 4,
          catalog_id: 1,
          page: pageVideo,
          category_id: filterCategory,
        }).then((res) => {
          setDataVideo(res?.data?.data);
          setPaginateVideo(res?.data?.paginate);
        });
      } catch (error) {
        setDataVideo([]);
      }
    },
    [filterCategory]
  );

  useEffect(() => {
    fetchBook();
    fetchImage();
    fetchVideo();
    fetchDocument();
  }, [fetchBook, fetchDocument, fetchImage, fetchVideo]);

  const onChangePaginationBook = (page) => {
    fetchBook(page);
  };
  const onChangePaginationImage = (page) => {
    const yOffset = -75;
    const element = document.getElementById("section-library");
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
    fetchImage(page);
  };

  const onChangePaginationVideo = (page) => {
    const yOffset = -75;
    const element = document.getElementById("section-library");
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
    fetchVideo(page);
  };

  const closeModalGallery = () => {
    setModalGallery({
      visible: false,
      data: [],
    });
    dispatch(setHiddenHeader(false));
  };

  const handleGallery = (gallery) => {
    setModalGallery({
      visible: true,
      data: gallery,
    });
  };
  const handleHideHeader = () => {
    dispatch(setHiddenHeader(true));
  };

  const handleFilterCategory = (type) => {
    setFilterCategory(type);
    setTimeout(() => {
      const gglang = Cookies.get("googtrans");
      changeLanguage(gglang ? gglang.replace("/vi/", "") : "vi");
    }, 500);
  };

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <section className="section-library-home" id="section-library">
      {modalGallery.visible ? (
        <ModalGallery
          visible={modalGallery.visible}
          gallery={modalGallery.data}
          closeModal={closeModalGallery}
        />
      ) : null}

      <div className="block-title-main">
        <div className="block-title-main__title">
          <p className="block-title-main__title-library">
            <Translate>{t("home-page.title-library")}</Translate>
          </p>
        </div>

        <div className="block-tab-library">
          <Select
            options={categoryOption}
            onChange={handleFilterCategory}
            className="library-filter-wrap"
            defaultValue={filterCategory}
          />
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span className="tab-title-text">
                  <Translate>{t("home-page.title-child-image")}</Translate>
                </span>
              }
              key="1"
            >
              <div className="content-main content-main-image row">
                {dataImage?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12 col-sm-6	col-md-4 col-xl-3 "
                      onClick={handleHideHeader}
                    >
                      <div
                        className="content-main-image__item"
                        onClick={() => handleGallery(item?.attach_files)}
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_ENDPOINT +
                            item?.attach_files[0]?.url
                          })`,
                        }}
                      >
                        {item?.categories && (
                          <div className="categories-title">
                            <p>{item?.categories?.title}</p>
                          </div>
                        )}
                        <div className="bg-gradient"></div>
                        <div className="title-img">
                          <span className="label content-custom">
                            {t("home-page.title-child-image")}
                          </span>
                          <p className="content-custom">{item?.title}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {paginateImage?.total > 8 && (
                <PaginationCustom
                  className="pagination-home-page"
                  current={paginateImage?.current_page}
                  pageSize={8}
                  onChange={onChangePaginationImage}
                  total={paginateImage?.total}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <span className="tab-title-text">
                  {t("home-page.title-child-video")}
                </span>
              }
              key="2"
            >
              <div className="content-main content-main-image content-main-video row">
                {dataVideo?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12 col-md-6 videoPlayerLibrary"
                    >
                      <div
                        className="content-main-image__item"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_ENDPOINT + item?.thumbnail
                          })`,
                        }}
                      >
                        {!item?.url_video && (
                          <>
                            {item?.attach_files.length > 0 && (
                              <video
                                className="item-video"
                                controls
                                id={`item-video-${item?.id}`}
                                style={{ display: "none" }}
                              >
                                <source
                                  src={
                                    process.env.REACT_APP_ENDPOINT +
                                      item?.attach_files[0]?.url ||
                                    item?.url_video
                                  }
                                  type="video/mp4"
                                />
                              </video>
                            )}
                          </>
                        )}

                        {item?.categories && (
                          <div className="categories-title">
                            <p>{item?.categories?.title}</p>
                          </div>
                        )}

                        <div className="bg-gradient"></div>
                        {!item?.url_video ? (
                          <a data-fancybox href={`#item-video-${item?.id}`}>
                            <img className="icon-play" src={iconPlay} alt="" />
                          </a>
                        ) : (
                          <a data-fancybox href={item?.url_video}>
                            <img className="icon-play" src={iconPlay} alt="" />
                          </a>
                        )}

                        <div className="title-video">
                          <span className="label content-custom">
                            {t("home-page.title-child-video")}
                          </span>
                          <p className="content-custom">{item?.title}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {paginateVideo?.total > 4 && (
                <PaginationCustom
                  className="pagination-home-page"
                  current={paginateVideo?.current_page}
                  pageSize={4}
                  onChange={onChangePaginationVideo}
                  total={paginateVideo?.total}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <span className="tab-title-text">
                  <Translate>{t("home-page.title-child-book")}</Translate>
                </span>
              }
              key="3"
            >
              <div className="content-main content-main-image row">
                {dataBook?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12 col-sm-6 col-md-4 col-xl-3"
                    >
                      <a
                        href={
                          process.env.REACT_APP_ENDPOINT +
                          item?.attach_files[0]?.url
                        }
                        target="__blank bl"
                      >
                        <div
                          className="content-main-image__item"
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_ENDPOINT + item?.bgr_image
                            })`,
                          }}
                        >
                          {item?.categories && (
                            <div className="categories-title categories-title-book">
                              <p>{item?.categories?.title}</p>
                            </div>
                          )}
                          <div className="bg-gradient"></div>
                          <div className="title-img">
                            <span className="label content-custom">
                              {t("home-page.title-child-book")}
                            </span>
                            <p className="content-custom">{item?.title}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
              {paginateBook?.total > 8 && (
                <PaginationCustom
                  className="pagination-home-page"
                  current={paginateBook?.current_page}
                  pageSize={8}
                  onChange={onChangePaginationBook}
                  total={paginateBook?.total}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <span className="tab-title-text">
                  <Translate>{t("home-page.title-child-document")}</Translate>
                </span>
              }
              key="4"
            >
              <div className="content-main content-main-document row">
                {dataTypeDocument?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12 col-sm-6	col-md-4 col-xl-3"
                    >
                      <Link to={`/document/${item?.document_type}`}>
                        <div className="content-main-document__item">
                          <img src={iconFolder} alt="" />
                          <p className="content-custom">{item?.name}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </section>
    // </Translator>
  );
};

export default SectionLibrary;
