import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/category-document/category-document.scss";
import vectorNext from "../../assets/images/home-update/vector-next.svg";
import vectorPreview from "../../assets/images/home-update/vector-preview.svg";
import { URL_API_DETAIL_TECHNICAL } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { getDataByID } from "../../utils/service";
import download from "../../assets/images/icons/download.svg";

const CategorySkillDetail = () => {
  const { idTechniques, id } = useParams();
  const [dataDetail, setDataDetail] = useState({});
  useEffect(() => {
    getDataDetail();
  }, [id]);

  const getDataDetail = () => {
    try {
      if (id) {
        getDataByID(URL_API_DETAIL_TECHNICAL, id).then((res) => {
          setDataDetail(res?.data);
        });
      }
    } catch (error) {
      setDataDetail({});
    }
  };

  return (
    <>
      <div className='category-document'>
        <div className='container-1200'>
          <div className='banner-category-document'>
            <ul className='banner-category-document__breadcrumb'>
              <li>
                <span>
                  <Link to='/'>Trang chủ</Link>
                </span>
              </li>
              <li>
                <span>Kỹ thuật</span>
              </li>
            </ul>
            <p className='title-category-document'>{dataDetail?.title}</p>
          </div>
          <div className='content-category-document'>
            <div
              dangerouslySetInnerHTML={{ __html: dataDetail?.content }}
              className='content-main ck-content'
            ></div>
            {dataDetail?.attach_files?.length > 0 && (
              <div
                className='d-flex align-items-center justify-content-between mt-4'
                style={{ background: "#F7F7FC", padding: "15px" }}
              >
                <div style={{ marginLeft: "24px" }}>
                  {/* <Translate> */}
                  {dataDetail?.title}
                  {/* </Translate> */}
                </div>
                <div>
                  <button
                    className='d-flex'
                    style={{
                      background: "#FC3A52",
                      color: "#fff",
                      border: "none",
                      padding: "12px 12px 12px 12px ",
                      outline: "none",
                    }}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_ENDPOINT_API}download?file=${dataDetail?.attach_files[0]}&name=${dataDetail?.title}`
                      );
                    }}
                  >
                    <img src={download} className='pr-2' />
                    <span>Tải xuống</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySkillDetail;
