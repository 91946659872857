import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../routes/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Translator, Translate } from "react-auto-translate";

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "vi",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <>
      {/* <Translator> */}
      {/* // from="vi" // to={localStorage.getItem("to")}
      // googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc" // > */}
      <Router>
        <Routes />
      </Router>
      {/* </Translator> */}
    </>
  );
}

export default App;
