import React from "react";
import DescriptionImage from "./DescriptionImage";
import LazyLoad from "react-lazyload";

const ImageGallery = ({
  title,
  author,
  time,
  nameClass,
  image,
  id,
  handleGallery,
  gallery,
  haveDescription,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={nameClass ? nameClass : "gallery_img fade-in"}
      onClick={() => handleGallery(gallery)}
    >
      <LazyLoad>
        <img
          src={image ? process.env.REACT_APP_ENDPOINT + image : null}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
          alt=""
        />
      </LazyLoad>
      {!haveDescription ? null : (
        <DescriptionImage title={title} author={author} time={time} />
      )}
    </a>
  );
};

export default ImageGallery;
