import React from "react";
import { formatDate } from "../utils/functions";
import { useTranslation } from "react-i18next";

const TitleUnderLine = ({ title, time, author }) => {
  const { t } = useTranslation();
  return (
    <div className="title_under_line text-center mb-3 mb-md-5 ">
      <h4 className="title-block">
        {title}
        {author && time && (
          <p className="time_author_text text-center mb-2 mt-2">
            {t("common.by")} {author} | {formatDate(time)}
          </p>
        )}
      </h4>
    </div>
  );
};

export default TitleUnderLine;
