import React from "react";
import {useTranslation} from "react-i18next";
import { CalendarOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import Button1 from "../../components/Button";
import IconMap from "../../assets/images/icons/VectorMap.svg";
import ImageDefault from "../../assets/images/bannerHistory.jpg";

export const CardKyThi = ({ info, className }) => {
  return (
    <div className={className}>
      <Link to={`/ky-thi/${info.id}`} className="kyThi-card__item">
        <div
          className="kyThi-card__img"
          style={{
            backgroundImage: `url(${
              info["attach_files"].length
                ? process.env.REACT_APP_ENDPOINT + info["attach_files"][0]
                : ImageDefault
            })`,
          }}
        />
        <div className="kyThi-card__info">
          <h4 className="kyThi-card__name">{info.title}</h4>
          <p className="kyThi-card__other mb-0">
            <CalendarOutlined style={{ paddingTop: 2 }} />
            <span>&nbsp;{info["exam_date"]}</span>
          </p>
          <p className="kyThi-card__other mb-0">
            <span>
              <img src={IconMap} alt="icon" />
            </span>
            <span>&nbsp;{info.address}</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

const KyThiCards = ({ data, isHasMore, fetchMoreData, loadingMore }) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="kyThi-card row">
        {data.map((item) => (
          <CardKyThi
            key={item.id}
            info={item}
            className={"col-12 col-sm-6 col-lg-4 mb-3 pt-2"}
          />
        ))}
      </div>
      {isHasMore && (
        <div className="text-center mb-3 pt-3">
          <Button1
            text={t("tech-active.more")}
            type="button"
            onClick={fetchMoreData}
            loading={loadingMore}
            nameClass="font-weight-bold text-capitalize"
            styles={{ width: "auto" }}
          />
        </div>
      )}
    </>
  );
};

export default KyThiCards;
