import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-css";

import TitleLine from "../../components/TitleLine";
import { getTakenData } from "../../utils/service";
import { URL_API_EVENT_DETAIL, TEXT_ERROR_API } from "../../utils/constants";
import "../../assets/styles/detail/detail.scss";
import TitleUnderLine from "../../components/TitleUnderLine";
import RelativedEvents from "./RelativedEvents";
import ImageGallery from "../../components/ImageGallery";
import ModalGallery from "../../components/ModalGallery";
import DonateInfo from "./DonateInfo";
import LoadingContainer from "../../components/LoadingContainer";
import { Translator, Translate } from "react-auto-translate";

const breakpointColumnsObj = {
  default: 3,
  768: 2,
  576: 1,
};

const DetailPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [modalGallery, setModalGallery] = useState({
    visible: false,
    data: [],
  });
  const [detail, setDetail] = useState({
    data: {},
    loading: true,
  });
  const getDataForDetail = () => {
    if (id) {
      getTakenData(URL_API_EVENT_DETAIL(id))
        .then((res) => {
          setDetail({
            loading: false,
            data: res.data ? res.data : {},
          });
        })
        .catch((err) => {
          alert(TEXT_ERROR_API);
        });
    }
  };
  const handleGallery = () => {
    setModalGallery({
      visible: true,
      data: detail.data.attach_files,
    });
  };
  // close modal gallery
  const closeModalGallery = () => {
    setModalGallery({
      visible: false,
      data: [],
    });
  };
  useEffect(() => {
    if (!detail.loading) {
      setDetail({
        ...detail,
        loading: true,
      });
    }
    getDataForDetail();
  }, [id]);

  if (detail.loading || !detail.data.id) {
    return <LoadingContainer />;
  }
  const isHaveImages =
    detail.data.attach_files && detail.data.attach_files.length !== 0;

  return (
    <>
      <LoadingContainer hidden={!detail.loading} />
      {!detail.loading && (
        <div className="detail_page">
          <div className="container fade-in">
            {modalGallery.visible ? (
              <ModalGallery
                visible={modalGallery.visible}
                gallery={modalGallery.data}
                closeModal={closeModalGallery}
              />
            ) : null}

            <TitleUnderLine title={detail.data.title}></TitleUnderLine>
            <div
              className="detail_page--content_post ck-content"
              dangerouslySetInnerHTML={{ __html: detail.data.detail }}
            ></div>
            {detail.data.type === 3 && (
              <DonateInfo info={detail.data.receiver_info} />
            )}

            {isHaveImages && (
              <div className="detail_page--image_detail">
                <TitleLine
                  title={{
                    // name: <Translate>t("common.imageDetailPost")</Translate>,
                    name: t("common.imageDetailPost"),
                  }}
                />
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="gallery_wrapper"
                  columnClassName="gallery_wrapper-grid_column"
                >
                  {detail.data.attach_files.map((item, index) => (
                    <ImageGallery
                      handleGallery={handleGallery}
                      id={item.id}
                      key={index.toString()}
                      image={item}
                      haveDescription={false}
                      gallery={detail.data.attach_files}
                    />
                  ))}
                </Masonry>
              </div>
            )}
            <LazyLoad>
              <RelativedEvents id={id} />
            </LazyLoad>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailPage;
