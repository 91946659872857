import React from "react";
import { useHistory } from "react-router-dom";
import { Tag } from "antd";

import "./hashtag.scss";

export default function HashtagCommon({ dataHashtag }) {
  const history = useHistory();
  const handleClick = (item) => {
    history.push(`/detail-search?search=${encodeURIComponent(item)}`);
  };
  return (
    <div className="commonHashtag">
      {dataHashtag &&
        dataHashtag?.map((item) => (
          <div onClick={() => handleClick(item)}>
            <Tag>#{item}</Tag>
          </div>
        ))}
    </div>
  );
}
