import { ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
import { setScrollPage } from "./../actions/index";
import { useDispatch } from "react-redux";

import "../assets/styles/scrollToTop.scss";

const ButtonScrollToTop = ({ show }) => {
  const dispatch = useDispatch();

  const scrollTopTop = () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    dispatch(setScrollPage(""));
  };
  return (
    <button
      type="button"
      onClick={scrollTopTop}
      className={`${show ? "back-to-top" : "back-to-top hide"}`}
    >
      <ArrowUpOutlined />
    </button>
  );
};

export default ButtonScrollToTop;
