import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu, message, Skeleton } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

import { postData, getTakenData } from "../../../utils/service";
import {
  URL_API_ACTIVITY,
  URL_GROUP_TECHNIQUES,
} from "../../../utils/constants";

import CardClub from "../../../components/CardClub";
import BreadcrumbComponent from "../../../components/BreadcrumbCommon";
import BannerCommon from "../../../components/BannerCommon";
import PaginationCustom from "../../../components/pagination";

import "./../Techniques/Techniques.scss";

const Techniques = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { idTechniques } = useParams();

  const [nameGroupTechiques, setNameGroupTechiques] = useState([]);
  const [groupTechniques, setGroupTechniques] = useState([]);
  const [infomationTechniques, setInfomationTechniques] = useState({});
  const [loading, setLoading] = useState(true);
  const [imageBanner, setImageBanner] = useState("");

  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 12,
    total: 0,
  });

  const skeletonsDefault = Array.from({ length: 12 });

  useEffect(() => {
    const payload = {
      page: params?.currentPage,
      per_page: params?.pageSize,
      keyword: "",
      type_menu: "1",
      menu_id: idTechniques,
    };

    postData(URL_API_ACTIVITY, payload)
      .then((res) => {
        const convertTechiques = res?.data?.data?.map((item) => ({
          ...item,
          name: item?.title,
          image: item?.image,
        }));
        setGroupTechniques(convertTechiques);
        setParams({ ...params, total: res?.data?.paginate?.total });
      })
      .catch((error) =>
        message.error(error || t("groupTechniques.techniquesFailed"))
      )
      .finally(() => setLoading(false));

    getTakenData(URL_GROUP_TECHNIQUES)
      .then((res) => {
        setNameGroupTechiques(res?.data?.[0].submenus);
        const convertGroupTechiques = res?.data[0]?.submenus?.filter(
          (item) => item?.id == idTechniques
        );
        setInfomationTechniques(convertGroupTechiques?.[0]);
        const imageByType = res?.data?.[0]?.submenus?.find(
          (item) => item.id === Number(idTechniques)
        );
        setImageBanner(imageByType.image);
      })
      .catch((error) =>
        message.error(error || t("groupTechniques.techniquesFailed"))
      )
      .finally(() => setLoading(false));
  }, [params?.currentPage, idTechniques]);

  const menu = (
    <Menu>
      {nameGroupTechiques?.map((data) => {
        return (
          <Menu.Item key={data?.id}>
            <Link to={`/group-techniques/${data?.id}`}>{data?.name}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const dataBreadcrumbs = [
    { title: t("club-list.home"), url: "/" },
    {
      title: t("groupTechniques.techniques"),
      url: "/group-techniques",
      menu: menu,
    },
    {
      title: !infomationTechniques?.name
        ? `${t("groupTechniques.techniques")}...`
        : `${infomationTechniques?.name}`,
    },
  ];

  const handleChangeCurrentPage = (e) => {
    setLoading(true);
    setParams({ ...params, currentPage: e });
  };

  const handleGroupTechniques = (id) => {
    if (idTechniques) {
      history.push(`/group-techniques/${idTechniques}/details/${id}`);
    }
  };

  return (
    <>
      <BannerCommon
        img={imageBanner}
        text1={
          !infomationTechniques?.name
            ? `${t("groupTechniques.techniques")}...`
            : `${infomationTechniques?.name}`
        }
      />
      <div className="container ">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />
        <div className="content row">
          {loading
            ? skeletonsDefault?.map((_, index) => (
                <Skeleton
                  key={index}
                  active
                  className="col-12 col-sm-6 col-md-4 col-lg-3"
                />
              ))
            : groupTechniques?.length > 0 &&
              groupTechniques?.map((item) => (
                <div
                  key={item?.id}
                  id={item?.id}
                  className="col-12 col-sm-6 col-md-4 col-lg-3 content__card"
                >
                  <CardClub
                    data={item}
                    handleClick={handleGroupTechniques}
                    isButton={false}
                    technical={true}
                  />
                </div>
              ))}
        </div>

        {params?.total > 4 && (
          <div className="container__pagination">
            <PaginationCustom
              current={params?.currentPage}
              pageSize={params?.pageSize}
              total={params?.total}
              onChange={handleChangeCurrentPage}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Techniques;
