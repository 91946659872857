import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

const MapCommon = (props) => {
  const { t } = useTranslation("translation");
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const { lat, lng, address } = props;

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  const center = {
    lat: lat,
    lng: lng,
  };

  const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

   return (
    <div style={{ height: "40vh", width: "100%", position: "relative" }}>
      <Map
        onClick={onMapClicked}
        google={props.google}
        zoom={15}
        center={center}
      >
        <Marker
          position={{ lat: lat, lng: lng }}
          onClick={onMarkerClick}
          name={address}
        />
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          <div>
            <a rel="noopener noreferrer" target="_blank" href={googleMapsUrl}>
              {address || t("block-branch.address")}
            </a>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(MapCommon);
