import { combineReducers } from "redux";
// import user from "./user";
import HomeReducer from "./home";
import NewsReducer from "./news";
import MapsReducer from "./maps";
import SearchReducer from "./search";
import EventsReducer from "./events";
import LibraryReducer from "./library";
import ScrollPage from "./scrollpage";
import HandleShowHeader from "./hidden";

const reducers = combineReducers({
  homepage: HomeReducer,
  newspage: NewsReducer,
  maps: MapsReducer,
  eventspage: EventsReducer,
  searchpage: SearchReducer,
  librarypage: LibraryReducer,
  scrollpage: ScrollPage,
  handleShowHeader: HandleShowHeader,
});

export default reducers;
