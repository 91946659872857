export const ACTION_TYPE = {
  SET_DATA: "SET_DATA_HOME",
};
const defaultState = {
  libraries: [],
  events: [],
  news: [],
  banners: [],
  about: null,
};
const reducers = (state = defaultState, actions) => {
  switch (actions.type) {
    case ACTION_TYPE.SET_DATA: {
      return actions.payload;
    }

    default:
      return state;
  }
};
export default reducers;
