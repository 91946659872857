const defaultState = {
  idsection: ""
};

const reducers = (state = defaultState, actions) => {
  switch (actions.type) {
    case "SET_ID_SCROLL": {
      return {
        ...state,
        idsection: actions.id
      };
    }
    default:
      return state;
  }
};

export default reducers;
