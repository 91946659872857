import React, { useEffect, useState } from "react";
import { URL_API_BANNERS, URL_API_BRANCH_IN_AREA } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getTakenData, postData } from "../../utils/service";

import "./../ClubGroup/ClubGroup.scss";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import CardClub from "../../components/CardClub";
import BannerCommon from "../../components/BannerCommon";

function ClubGroup() {
  const { t } = useTranslation();
  const history = useHistory();

  const dataBreadcrumbs = [
    { title: t("club-list.home"), url: "/" },
    {
      title: t("club-list.header"),
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const [data, setData] = useState([]);
  const [imageBanner, setImageBanner] = useState("");

  const getDataClub = () => {
    try {
      getTakenData(URL_API_BRANCH_IN_AREA).then((res) => {
        setData(res?.data);
      });
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "BRANCH"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  }, []);

  useEffect(() => {
    getDataClub();
  }, []);
  const handleClick = (id) => {
    history.push(`branchsinareas/${id}`);
  };
  return (
    <>
      <BannerCommon img={imageBanner} text1={t("club-list.header")} />
      <div className="container ">
        <BreadcrumbComponent dataBreadcrumb={dataBreadcrumbs} />
        <div className="content row">
          {data.map((item) => (
            <div
              key={item.id}
              id={item.id}
              className="col-12 col-sm-6 col-md-4 content__card"
            >
              <CardClub data={item} handleClick={handleClick} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default ClubGroup;
