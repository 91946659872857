import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Translator } from "react-auto-translate";

import { postData } from "../../utils/service";
import { URL_API_POSTS_LIST_TECHNICAL_ACTIVE } from "../../utils/constants";
import Breadcrumb from "../../components/BreadcrumbCommon";
import BannerCommon from "../../components/BannerCommon";

import vectorPreview from "../../assets/images/home-update/vector-preview.svg";
import vectorNext from "../../assets/images/home-update/vector-next.svg";

import "../../assets/styles/category-document/category-document.scss";

const ActivityUpdate = () => {
  const { id: menu_id } = useParams();
  const { search } = useLocation();
  const [dataDetailActivity, setDataDetailActivity] = useState({});
  const { t } = useTranslation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const currentID = searchParams.get("id");

  useEffect(() => {
    getDetailActivity();
  }, [menu_id, currentID]);

  const getDetailActivity = (pageDocumentType = 1) => {
    postData(URL_API_POSTS_LIST_TECHNICAL_ACTIVE, {
      parent_id: 2,
      menu_id: menu_id,
      current_id: currentID || null,
    }).then((res) => {
      setDataDetailActivity(res?.data?.data);
    });
  };

  return (
    <Translator
      from="vi"
      to={localStorage.getItem("to")}
      googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    >
      <div className="wrap">
        <div className="wrap__banner">
          <BannerCommon text1={dataDetailActivity?.title} />
        </div>

        <div className="container">
          <Breadcrumb
            dataBreadcrumb={[
              { title: t("activity.home"), url: "/" },
              { title: t("activity.title"), url: "/activity-new" },
              { title: dataDetailActivity?.title },
            ]}
          />

          <div className="content-category-document">
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{ __html: dataDetailActivity?.content }}
            ></div>
          </div>
        </div>

        <div className="block-next-preview-document">
          <div className="block-next-preview">
            <div className="block-next-preview__left">
              {dataDetailActivity?.prev && (
                <Link to={`/activity/${dataDetailActivity?.prev?.id}`}>
                  <img src={vectorPreview} alt="" />
                  {/* <Translate> */}
                  {dataDetailActivity?.prev?.name}
                  {/* </Translate> */}
                </Link>
              )}
            </div>
            <div className="block-next-preview__right">
              {dataDetailActivity?.next && (
                <Link to={`/activity/${dataDetailActivity?.next?.id}`}>
                  {/* <Translate> */}
                  {dataDetailActivity?.next?.name}
                  {/* </Translate> */}
                  <img src={vectorNext} alt="" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Translator>
  );
};

export default ActivityUpdate;
