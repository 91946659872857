export const LIBRARY_ACTION_TYPE = {
  SET_DATA: "SET_DATA_LIBRARY",
  SET_TAB_CURRENT: "SET_TAB_CURRENT",
};
const defaultState = {
  currentTab: 0,
};
const reducers = (state = defaultState, actions) => {
  switch (actions.type) {
    case LIBRARY_ACTION_TYPE.SET_DATA: {
      return actions.payload;
    }
    case LIBRARY_ACTION_TYPE.SET_TAB_CURRENT: {
      return {
        ...state,
        currentTab: actions.payload,
      };
    }
    default:
      return state;
  }
};
export default reducers;
