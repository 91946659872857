import React from "react";
import "./MapContact.scss";

function MapContact() {
  return (
    <div className="wrapMap">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3826.217581188451!2d107.58596627520134!3d16.464516784273766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3141a13987437fab%3A0x765f2c046d9a703c!2zOCBUcsawxqFuZyDEkOG7i25oLCBWxKluaCBOaW5oLCBUaMOgbmggcGjhu5EgSHXhur8sIFRo4burYSBUaGnDqm4gSHXhur8sIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1720370808638!5m2!1svi!2s"
        maxWidth="712px"
        maxHeight="639px"
        width="100%"
        height="639px"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Nghia Dung Karate Do"
      ></iframe>
    </div>
  );
}

export default MapContact;
