import React, { useState, useEffect } from "react";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Input } from "antd";

import "../../assets/styles/kyThi/kyThi.scss";
import LoadingContainer from "../../components/LoadingContainer";
import KyThiCards from "./KyThiCards";
import KyThiCalendar from "./KyThiCalendar";
import { postData } from "../../utils/service";
import { TEXT_ERROR_API, URL_API_EXAM } from "../../utils/constants";

const DATA_POST = {
  page: 1,
  per_page: 3,
  keyword: "",
};

const KyThi = () => {
  const { t } = useTranslation();
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [dataKyThi, setDataKyThi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isHasMore, setIsHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchDataKyThi = (page) => {
    postData(URL_API_EXAM, { ...DATA_POST, page })
      .then((res) => {
        setDataKyThi([...dataKyThi, ...res.data.data]);
        setLoading(false);
        setLoadingMore(false);
        if (res.data.paginate.next) {
          setIsHasMore(true);
        } else {
          setIsHasMore(false);
        }
      })
      .catch(() => {
        alert(TEXT_ERROR_API);
      });
  };

  useEffect(() => {
    if (currentPage) {
      fetchDataKyThi(currentPage);
    }
  }, [currentPage]);

  if (loading) {
    return <LoadingContainer />;
  }
  return (
    <>
      <LoadingContainer hidden={true} />
      <div className="kyThi">
        <div className="kyThi-header">
          <div className="container">
            <div className="kyThi-breadcrumb">
              <ul>
                <li>
                  <Link to="/">{t("common.home")} - &nbsp;</Link>
                </li>
                <li>{t("common.exam")}</li>
              </ul>
            </div>
            <h3 className="kyThi-title text-center">{t("common.exam")}</h3>

            <div className="row justify-content-between mt-5 kyThi-header-content">
              <form
                className="d-flex col-12 col-md-5 mb-2 mb-md-0"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Button
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  className="mr-2 kyThi-btn"
                  // style={{font}}
                />
                <Input
                  placeholder={t("common.searchExam")}
                  // onKeyPress={(e) => console.log(e)}
                />
              </form>
              <div className="col-12 col-md-5 text-right">
                <Button
                  htmlType="button"
                  style={{ width: 170, height: 50 }}
                  className={
                    isShowCalendar
                      ? "m-auto ml-md-auto mr-md-0 kyThi-btn active"
                      : "m-auto ml-md-auto mr-md-0 kyThi-btn"
                  }
                  icon={<CalendarOutlined />}
                  onClick={() => setIsShowCalendar(!isShowCalendar)}
                >
                  Calendar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          {!dataKyThi.length ? (
            <div className="">
              <p className="mb-2 mt-2 text-center">{t("common.empty")}</p>
            </div>
          ) : isShowCalendar ? (
            <KyThiCalendar data={dataKyThi} />
          ) : (
            <KyThiCards
              data={dataKyThi}
              isHasMore={isHasMore}
              loadingMore={loadingMore}
              fetchMoreData={() => {
                setCurrentPage((prev) => prev + 1);
                setLoadingMore(true);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default KyThi;
